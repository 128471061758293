import { Heading, Columns, Form, Button } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { formInquiryBeneficiaryCompany } from 'GlobalComponents/Form/ValidationSchemas';

import type { InquiryBeneficiaryEntryOrganization } from 'Components/InquiryBeneficiary/InquiryBeneficiarySlice';
import InputText from 'GlobalComponents/Form/InputText/InputText';
import SelectList, {
  OPTIONS_TYPES,
} from 'GlobalComponents/Form/SelectList/SelectList';

const { Field } = Form;
const { Column } = Columns;

interface InquiryBeneficiaryFormOrganizationPropsTypes {
  isDisabled?: boolean;
  append: any;
  field: any;
  index: any;
  update: any;
  indexArray: any;
  editMode: any;
  onModalClose?: any;
}

const InquiryBeneficiaryFormOrganization = ({
  append,
  update,
  isDisabled,
  field,
  index,
  indexArray,
  editMode,
  onModalClose,
}: InquiryBeneficiaryFormOrganizationPropsTypes) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InquiryBeneficiaryEntryOrganization>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: field,
    resolver: yupResolver(formInquiryBeneficiaryCompany),
  });

  const handleSubmitForm = (data: any) => {
    if (editMode === true) {
      update(indexArray, { ...data, orderId: index });
    } else {
      append({ ...data, orderId: index });
    }
    return onModalClose('organization');
  };

  return (
    <Field>
      <Heading subtitle>
        {t('inquiry_beneficiary.modal_organization.title')}
      </Heading>
      <Columns>
        <Column>
          <Controller
            control={control}
            name='name'
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <InputText
                name={name}
                inputRef={ref}
                label='inquiry_beneficiary.modal_organization.name'
                onChange={onChange}
                onBlur={onBlur}
                value={field.first_name || value}
                error={errors && errors.name}
                isDisabled={isDisabled}
              />
            )}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Controller
            control={control}
            name='country'
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectList
                label='inquiry_beneficiary.modal_organization.country'
                onChange={onChange}
                onBlur={onBlur}
                value={field.country || value}
                error={errors && errors.country}
                isDisabled={isDisabled}
                optionsType={OPTIONS_TYPES.COUNTRIES}
                isRequired={false}
              />
            )}
          />
        </Column>
      </Columns>
      {onModalClose && (
        <Button
          color='primary'
          onClick={handleSubmit(handleSubmitForm)}
          disabled={isDisabled}>
          Submit
        </Button>
      )}
    </Field>
  );
};

export default InquiryBeneficiaryFormOrganization;
