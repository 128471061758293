import * as yup from 'yup';

// import { FILE_MAX_SIZE } from 'helpers';

// const fileSizeTest = yup
//   .array()
//   .min(1, 'This document is mandatory')
//   .required()
//   .test('fileSize', 'The file is too large', (value: any) => {
//     if (!value?.length) return true; // attachment is optional
//     return value[0].size <= FILE_MAX_SIZE;
//   });

yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
});

// PHONE VERIFICATION
export const formPhoneVerificationSchema = yup.object({
  phone: yup
    .string()
    .min(6, 'Min 6 numbers')
    .max(20, 'Max 20 numbers')
    // .matches(
    //   /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
    //   'Formats accepted are: 10 numbers or XXX-XXX-XXXXX'
    // )
    .required(),
  // code: yup.string(),
});

export const formPhoneSmsCodeSchema = yup.object({
  code: yup.string().length(6, 'Must be 6 numbers').required(),
});

export const formIdentitySchema = yup.object({
  date_of_birth: yup
    .string()
    .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)
    .required(),
  first_name: yup.string().required(),
  identity_document: yup.object({
    // files: fileSizeTest,
    files: yup.array().min(1, 'This document is mandatory').required(),
    type: yup.string().required(),
  }),
  is_pep: yup.boolean().required(),
  pep_explanation: yup.string().when('is_pep', {
    is: (is_pep: boolean) => is_pep === true,
    then: yup.string().required(),
  }),
  last_name: yup.string().required(),
  nationality: yup.string().required(),
});

export const formIdentityOnfidoSchema = yup.object({
  date_of_birth: yup.string().required(),
  first_name: yup.string().required(),
  is_pep: yup.boolean().required(),
  pep_explanation: yup.string().when('is_pep', {
    is: (is_pep: boolean) => is_pep === true,
    then: yup.string().required(),
  }),
  last_name: yup.string().required(),
  nationality: yup.string().required(),
});

// RESIDENCE
export const formResidenceSchema = yup.object({
  address: yup.string().required(),
  country: yup.string().required(),
  us_state: yup.string().when('country', {
    is: (country: string) => country === 'US',
    then: yup.string().required(),
  }),
  document: yup.object({
    // files: fileSizeTest,
    files: yup.array().min(1, 'This document is mandatory').required(),
    type: yup.string().required(),
  }),
});

// INCOME
export const formIncomeSchema = yup.object({
  annual_income: yup.object({
    value: yup.string().required(),
  }),
  expected_yearly_trade_volume: yup.object({
    value: yup.string().required(),
  }),
  net_worth: yup.object({
    value: yup.string().required(),
  }),
  industry: yup.string().required(),
  occupation: yup.string().required(),
  is_high_risk: yup.boolean().required(),
});

// ORIGIN OF FUNDS
export const formOriginOfFundsSchema = yup.object({
  categories: yup
    .array()
    .min(1, 'You must select at least 1 category')
    .required(),
  explanation: yup.string().when('categories', {
    is: (categories: string[]) => categories.includes('other'),
    then: yup.string().required(),
  }),
});

// UBO
export const formUboEntrySchema = yup.object({
  date_of_birth: yup
    .string()
    .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)
    .required(),
  first_name: yup.string().required(),
  identity_document: yup.object({
    // files: fileSizeTest,
    files: yup.array().min(1, 'This document is mandatory').required(),
    type: yup.string().required(),
  }),
  is_pep: yup.boolean().required(),
  pep_explanation: yup.string().when('is_pep', {
    is: (is_pep: boolean) => is_pep === true,
    then: yup.string().required(),
  }),
  last_name: yup.string().required(),
  nationality: yup.string().required(),
  address: yup.string().required(),
  country: yup.string().required(),
  us_state: yup.string().when('country', {
    is: (country: string) => country === 'US',
    then: yup.string().required(),
  }),
});

export const formUboSchema = yup.object({
  entries: yup.array().of(formUboEntrySchema).required(),
  include_self_as_ubo_entry: yup.boolean().required(),
});

export const formInquiryGenericDocumentsSchema = yup.object({
  files: yup.array().min(1, 'This document is mandatory').required(),
});

export const formInquiryOOFExplanationModalSchema = yup.object({
  description: yup.string().required(),
  document: yup.object({
    files: yup.array().min(1, 'This document is mandatory').required(),
    type: yup.string(),
  }),
});

export const formInquiryOOFExplanationSchema = yup.object({
  explanation: yup.string().required(),
});

export const formInquiryBeneficiaryPerson = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  country_of_residence: yup.string(),
});

export const formInquiryBeneficiaryCompany = yup.object({
  name: yup.string().required(),
  country: yup.string(),
});
