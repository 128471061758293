import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGlobalStatus } from 'Store/GlobalStatusSlice';
import {
  ROUTE_STEP_OOF,
  ROUTE_STEP_INQUIRY_DOCUMENTS,
  ROUTE_STEP_INQUIRY_OOF_EXPLANATION,
  ROUTE_STEP_INQUIRY_BENEFICIARY,
} from 'Router/constants';

export const FILE_MAX_SIZE = 20971520;

export type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
};

export const useStatusChecker = (statusName: string): boolean => {
  const { initialSubmission, inquiry } = useSelector(getGlobalStatus);
  if (!initialSubmission) {
    return !inquiry?.allowed_submissions.includes(statusName);
  }
  return !!initialSubmission[statusName];
};

export const returnFileSize = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `(${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]})`;
};

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader != null && reader.result != null) {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      }
      reject();
    };
    reader.onerror = error => reject(error);
  });

export const convertFilesToBase64 = async (files: any) => {
  const convertedFiles = await Promise.all(
    files.map(async (file: any) => toBase64(file))
  );

  return convertedFiles;
};

export const isPepData = () => {
  const { t } = useTranslation();
  return [
    { name: t('global.yes'), key: 'true' },
    { name: t('global.no'), key: 'false' },
  ];
};
export const isPepSelfData = () => {
  const { t } = useTranslation();

  return [
    { name: t('global.yes'), key: 'true' },
    { name: t('global.no'), key: 'false' },
  ];
};

export const isHighRisk = () => {
  const { t } = useTranslation();

  return [
    { name: t('global.yes'), key: 'true' },
    { name: t('global.no'), key: 'false' },
  ];
};

export const stepsMappingToRoute = (step: string) => {
  switch (step) {
    case 'origin_of_funds_declaration':
      return ROUTE_STEP_OOF;
    case 'origin_of_funds_explanation':
      return ROUTE_STEP_INQUIRY_OOF_EXPLANATION;
    case 'generic_document':
      return ROUTE_STEP_INQUIRY_DOCUMENTS;
    case 'order_beneficiary_declaration':
      return ROUTE_STEP_INQUIRY_BENEFICIARY;
    default:
      return step;
  }
};

export const stepsMappingToName = (step: string) => {
  const { t } = useTranslation();

  switch (step) {
    case 'identity':
      return t('steps_name.identity');
    case 'residence':
      return t('steps_name.residence');
    case 'income':
      return t('steps_name.income');
    case 'origin_of_funds_declaration':
      return t('steps_name.origin_of_funds_declaration');
    case 'origin_of_funds_explanation':
      return t('steps_name.origin_of_funds_explanation');
    case 'ubo':
      return t('steps_name.ubo');
    case 'generic_document':
      return t('steps_name.generic_document');
    case 'order_beneficiary_declaration':
      return t('inquiry_beneficiary.step_name');
    default:
      return step;
  }
};

export const getOrderIdsToSupport = (arr: []) =>
  arr.reduce((acc: any, el: any) => {
    const newValue = [...acc, el.external_order_id];
    return newValue;
  }, []);
