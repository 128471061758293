import { Container, Box, Heading, Columns, Form } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';

import type { IncomeStateTypes } from 'Components/Income/IncomeSlice';
import {
  replaceIncomeFields,
  getIncomeFields,
} from 'Components/Income/IncomeSlice';
import { getGlobalStatusCurrentTask } from 'Store/GlobalStatusSlice';
import {
  API_POST_INCOME,
  STATUS_TASK_INCOME,
  STATUS_INQUIRY_REQUEST,
} from 'Api/constants';
import { ROUTE_STEP_OOF, ROUTE_STEP_INQUIRY } from 'Router/constants';
import { formIncomeSchema } from 'GlobalComponents/Form/ValidationSchemas';
import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import InputText from 'GlobalComponents/Form/InputText/InputText';
import SelectList from 'GlobalComponents/Form/SelectList/SelectList';
import RadioButton from 'GlobalComponents/Form/RadioButton/RadioButton';
import { useStatusChecker, isHighRisk } from 'helpers';
import {
  incomeAnnualSelect,
  incomeExpectedTradingVolumeSelect,
  incomeIndustrySelect,
} from 'Components/Income/IncomeSelectData';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

const { Column } = Columns;

function Income() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const defaultFields = useSelector(getIncomeFields);
  const globalStatus = useSelector(getGlobalStatusCurrentTask);

  const isInquiry = globalStatus === STATUS_INQUIRY_REQUEST;

  const isSubmitted = useStatusChecker(STATUS_TASK_INCOME);

  const [{ loading: getLoading }, postIncomeData] = useAxios(
    {
      url: API_POST_INCOME,
      method: 'POST',
    },
    { manual: true }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IncomeStateTypes>({
    defaultValues: defaultFields,
    mode: 'onChange',
    resolver: yupResolver(formIncomeSchema),
  });

  const isDisabled = !!getLoading || isSubmitted;

  // Go next without sending data
  const handleGoNext = () => {
    navigate(`/${ROUTE_STEP_OOF}`);
  };

  const handleSubmitIncome = async (data: IncomeStateTypes) => {
    try {
      await postIncomeData({ data });

      dispatch(replaceIncomeFields(data));
      navigate(isInquiry ? `/${ROUTE_STEP_INQUIRY}` : `/${ROUTE_STEP_OOF}`);
    } catch (err) {
      handleError(err);
    }
  };

  const isHighRiskSelected = useWatch({
    control,
    name: 'is_high_risk',
  });

  return (
    <Container>
      <Heading>{t('income.title')}</Heading>
      <Box>
        <Columns>
          <Column>
            <Controller
              control={control}
              name='annual_income.value'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label='income.fields.annual_income'
                  onChange={onChange}
                  value={value || defaultFields.annual_income}
                  selectOptions={incomeAnnualSelect}
                  error={errors.annual_income?.value}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
          <Column>
            <Controller
              control={control}
              name='net_worth.value'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label='income.fields.net_worth'
                  onChange={onChange}
                  value={value || defaultFields.net_worth}
                  selectOptions={incomeAnnualSelect}
                  error={errors.net_worth?.value}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
          <Column>
            <Controller
              control={control}
              name='expected_yearly_trade_volume.value'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label='income.fields.expected_yearly_trade_volume'
                  onChange={onChange}
                  value={value || defaultFields.expected_yearly_trade_volume}
                  selectOptions={incomeExpectedTradingVolumeSelect}
                  error={errors.expected_yearly_trade_volume?.value}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Controller
              control={control}
              name='industry'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label='income.fields.industry'
                  onChange={onChange}
                  value={value}
                  selectOptions={incomeIndustrySelect}
                  error={errors.industry}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
          <Column>
            <Controller
              control={control}
              name='occupation'
              render={({ field: { onChange, value } }) => (
                <InputText
                  label='income.fields.occupation'
                  onChange={onChange}
                  value={value}
                  error={errors.occupation}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Form.Label>
              <span style={{ color: 'red' }}>*&nbsp;</span>
              {t('income.fields.question_high_risk')}
            </Form.Label>
            <RadioButton
              control={control}
              options={isHighRisk()}
              name='is_high_risk'
              isDisabled={isDisabled}
              isBool
              defaultChecked={defaultFields.is_high_risk}
            />
          </Column>
        </Columns>
      </Box>
      <NavigationButtons
        onClickNext={
          isSubmitted ? handleGoNext : handleSubmit(handleSubmitIncome)
        }
        isLoading={!!getLoading}
        isNextDisabled={isHighRiskSelected === undefined}
      />
    </Container>
  );
}

export default withErrorBoundary(Income, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
