import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

export interface ResidenceStateTypes {
  address: string;
  country: string;
  us_state?: string;
  document_types?: string[];
  document: {
    type: string;
    files: string[];
  };
}

const initialState: ResidenceStateTypes = {
  address: '',
  country: '',
  document: {
    type: '',
    files: [],
  },
};

export const residenceSlice = createSlice({
  name: 'residence',
  initialState,
  reducers: {
    replaceResidenceFields: (
      state,
      action: PayloadAction<ResidenceStateTypes>
    ) => action.payload,
  },
});

export const { replaceResidenceFields } = residenceSlice.actions;

export const getResidenceFields = (state: RootState) => state.residence;

export default residenceSlice.reducer;
