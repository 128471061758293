import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { BityApiClientInterface } from '@bity/api';
import useAxios, { makeUseAxios, configure } from 'axios-hooks';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Axios from 'axios';
import { persistor } from 'Store/store';
import { replaceGlobalStatus } from 'Store/GlobalStatusSlice';

import {
  replaceCountriesData,
  getCountriesDataStore,
} from 'Store/CountriesDataSlice';

import Header from 'Components/Header/Header';
import Page404 from 'Components/Page404/Page404';
import VersionPage from 'Components/VersionPage/VersionPage';
import LoginPage from 'Components/LoginPage/LoginPage';
import Home from 'Components/Home/Home';
import PhoneVerification from 'Components/PhoneVerification/PhoneVerification';
import Identity from 'Components/Identity/Identity';
import Residence from 'Components/Residence/Residence';
import Income from 'Components/Income/Income';
import OriginOfFunds from 'Components/OriginOfFunds/OriginOfFunds';
import Ubo from 'Components/Ubo/Ubo';
import Success from 'Components/Success/Success';
import Inquiry from 'Components/Inquiry/Inquiry';
import InquiryDocuments from 'Components/InquiryDocuments/InquiryDocuments';
import InquiryOriginOfFundsExplanation from 'Components/Inquiry/InquiryOOFExplanation/InquiryOOFExplanation';
import InquiryBeneficiary from 'Components/InquiryBeneficiary/InquiryBeneficiary';
// import AccountChoice from 'Components/AccountChoice/AccountChoice';

import Loader from 'GlobalComponents/Loader/Loader';

import { BITY_DATA_API, API_BASE_URL } from 'Api/config';

import { useStatusChecker } from 'helpers';

import {
  ROUTE_STEP_PHONE,
  ROUTE_STEP_IDENTITY,
  ROUTE_STEP_RESIDENCE,
  ROUTE_STEP_INCOME,
  ROUTE_STEP_OOF,
  ROUTE_STEP_UBO,
  ROUTE_STEP_SUCCESS,
  ROUTE_STEP_INQUIRY,
  ROUTE_STEP_INQUIRY_DOCUMENTS,
  ROUTE_STEP_INQUIRY_OOF_EXPLANATION,
  ROUTE_STEP_INQUIRY_BENEFICIARY,
  // ROUTE_STEP_ACCOUNT_CHOICE,
} from 'Router/constants';
import {
  API_GET_STATUS,
  STATUS_INQUIRY_REQUEST,
  STATUS_INQUIRY_WAITING,
  DATA_API_COUNTRIES,
  DATA_API_COUNTRIES_ALPHA3,
  STATUS_NO_CURRENT_TASK,
} from 'Api/constants';

import apiRouteName from 'Router/routesMapping';

export interface AppPropsTypes {
  bityClient: BityApiClientInterface;
}

const useAxiosNoAuth = makeUseAxios({
  axios: Axios.create({ baseURL: BITY_DATA_API }),
});

function App({ bityClient }: AppPropsTypes) {
  const defaultCountiesData = useSelector(getCountriesDataStore);

  const [status, setStatus] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');

  const routerLocation = useLocation();
  const dispatch = useDispatch();

  const stepIsSubmitted = useStatusChecker(routerLocation.pathname.slice(1));
  const { apiUrl, action } = apiRouteName(routerLocation.pathname);

  const [{ loading: getStatusLoading }, getStatus] = useAxios(
    {
      url: API_GET_STATUS,
      method: 'GET',
    },
    { manual: true }
  );

  const [{ loading: getStepDataLoading }, getStepData] = useAxios(
    {
      url: apiUrl,
      method: 'GET',
    },
    { manual: true }
  );

  const [{ loading: getCountriesDataLoading }, getCountriesData] =
    useAxiosNoAuth(
      {
        url: `/${DATA_API_COUNTRIES}/${DATA_API_COUNTRIES_ALPHA3}`,
        method: 'GET',
      },
      { manual: true }
    );

  // populate redux store for countries data
  const handleReplaceCountriesData = async () => {
    const { data } = await getCountriesData();
    dispatch(replaceCountriesData(data));
  };

  // populate redux store for current step
  const handleReplaceStepData = async () => {
    if (apiUrl && action && status !== STATUS_INQUIRY_REQUEST) {
      const { data } = await getStepData();
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { created_at, ...rest } = data;

      dispatch(action(rest));
    }
  };

  // replace STATUS each time route changes
  const handleReplaceStatus = async () => {
    const { data } = await getStatus();
    dispatch(replaceGlobalStatus(data));
    setStatus(data.current_task);

    if (stepIsSubmitted && data.current_task !== STATUS_INQUIRY_REQUEST) {
      handleReplaceStepData();
    }
  };

  // logout
  const handleLogout = () => {
    persistor.purge();
    bityClient.resetOAuth();
    bityClient.fetchAuthorizationCode();
  };

  if (!bityClient.isAuthorized() || bityClient.isAccessTokenExpired()) {
    return (
      <>
        <Header
          handleLogout={handleLogout}
          isLoggedIn={
            !(
              bityClient.isAuthorized() ||
              !bityClient.isAccessTokenExpired() ||
              !!accessToken
            )
          }
        />
        <LoginPage handleLogin={handleLogout} />
      </>
    );
  }

  useEffect(() => {
    if (accessToken) {
      handleReplaceStatus();

      if (_.isEmpty(defaultCountiesData)) handleReplaceCountriesData();
    }
  }, [accessToken, routerLocation.key]);

  useEffect(() => {
    const token: any = localStorage.getItem('oauth2authcodepkce-state');
    const parsedToken = JSON.parse(token);
    const newToken = parsedToken.accessToken.value;

    setAccessToken(newToken);

    const axios = Axios.create({
      baseURL: API_BASE_URL,
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    });

    configure({ axios });
  }, []);

  const isLoading =
    !accessToken ||
    getStatusLoading ||
    getStepDataLoading ||
    getCountriesDataLoading;

  const renderHomeComponent = () => {
    if (status === STATUS_INQUIRY_WAITING || status === STATUS_NO_CURRENT_TASK)
      return <Success />;
    return <Home />;
  };

  return (
    <>
      <Header handleLogout={handleLogout} isLoggedIn={!!accessToken} />
      {isLoading ? (
        <Loader />
      ) : (
        <Routes>
          <Route
            path='/'
            element={
              status === STATUS_INQUIRY_REQUEST ? (
                <Inquiry />
              ) : (
                renderHomeComponent()
              )
            }
          />

          <Route path='/version' element={<VersionPage />} />
          <Route
            path={`/${ROUTE_STEP_PHONE}`}
            element={<PhoneVerification />}
          />
          <Route path={`/${ROUTE_STEP_IDENTITY}`} element={<Identity />} />
          <Route path={`/${ROUTE_STEP_RESIDENCE}`} element={<Residence />} />
          <Route path={`/${ROUTE_STEP_INCOME}`} element={<Income />} />
          <Route path={`/${ROUTE_STEP_OOF}`} element={<OriginOfFunds />} />
          <Route path={`/${ROUTE_STEP_UBO}`} element={<Ubo />} />
          <Route path={`/${ROUTE_STEP_INQUIRY}`} element={<Inquiry />} />
          <Route
            path={`/${ROUTE_STEP_INQUIRY_BENEFICIARY}`}
            element={<InquiryBeneficiary />}
          />
          <Route
            path={`/${ROUTE_STEP_INQUIRY_DOCUMENTS}`}
            element={<InquiryDocuments />}
          />
          <Route
            path={`/${ROUTE_STEP_INQUIRY_OOF_EXPLANATION}`}
            element={<InquiryOriginOfFundsExplanation />}
          />
          <Route path={`/${ROUTE_STEP_SUCCESS}`} element={<Success />} />

          {/* <Route
            path={`/${ROUTE_STEP_ACCOUNT_CHOICE}`}
            element={<AccountChoice />}
          /> */}
          <Route path='*' element={<Page404 />} />
        </Routes>
      )}
    </>
  );
}

export default App;
