const SELECT_FIRST_ITEM = { name: 'global.document_types.select', key: '' };

export const DOCUMENT_IDENTITY_SWISS = [
  {
    name: 'global.document_types.identity.national_identity_card',
    key: 'national_identity_card',
  },
  { name: 'global.document_types.identity.passport', key: 'passport' },
  {
    name: 'global.document_types.identity.driving_licence',
    key: 'driving_licence',
  },
  {
    name: 'global.document_types.identity.residence_permit',
    key: 'residence_permit',
  },
];

export const DOCUMENT_IDENTITY_EU = [
  {
    name: 'global.document_types.identity.national_identity_card',
    key: 'national_identity_card',
  },
  { name: 'global.document_types.identity.passport', key: 'passport' },
];

export const DOCUMENT_IDENTITY_OTHERS = [
  { name: 'global.document_types.identity.passport', key: 'passport' },
];

export const DOCUMENT_TYPES_RESIDENCE = [
  SELECT_FIRST_ITEM,
  { name: 'global.document_types.residence.utility_bill', key: 'utility_bill' },
  {
    name: 'global.document_types.residence.bank_statement',
    key: 'bank_statement',
  },
  {
    name: 'global.document_types.residence.residence_certificate',
    key: 'residence_permit',
  },
];

// Hardcoded list because Florian didnt want to duplicate the list in bity-country-data
export const EU_COUNTRY_LIST = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export const LANGUAGE_LIST = [
  { name: 'EN', key: 'en' },
  { name: 'FR', key: 'fr' },
  // { name: 'DE', key: 'de' },
];

export const INQUIRY_BENEFICIARY_CHOICE = [
  { name: 'inquiry_beneficiary.choice.select', key: 'null' },
  { name: 'inquiry_beneficiary.choice.is_self', key: 'is_self' },
  { name: 'inquiry_beneficiary.choice.some', key: 'some' },
  { name: 'inquiry_beneficiary.choice.other', key: 'other' },
  { name: 'inquiry_beneficiary.choice.unknown', key: 'unknown' },
];

export const DOCUMENT_IDENTITY_TYPES = (country: string) => {
  const isEuCountry = EU_COUNTRY_LIST.includes(country);

  if (country === 'CH') return [SELECT_FIRST_ITEM, ...DOCUMENT_IDENTITY_SWISS];

  return isEuCountry
    ? [SELECT_FIRST_ITEM, ...DOCUMENT_IDENTITY_EU]
    : [SELECT_FIRST_ITEM, ...DOCUMENT_IDENTITY_OTHERS];
};

export const useOnfidoConfigSelection = (countryList: any, country: string) => {
  const selectedCountry = Object.entries(countryList).filter(
    ([, { iso_3166_alpha2 }]: any) => iso_3166_alpha2 === country
  );

  const defaultConfig = {
    driving_licence: false,
    national_identity_card: false,
    residence_permit: false,
    passport: true,
  };

  const euConfig = {
    driving_licence: false,
    national_identity_card: {
      country: selectedCountry[0][0],
    },
    residence_permit: false,
    passport: {
      country: selectedCountry[0][0],
    },
  };

  const swissConfig = {
    driving_licence: {
      country: selectedCountry[0][0],
    },
    national_identity_card: {
      country: selectedCountry[0][0],
    },
    residence_permit: {
      country: selectedCountry[0][0],
    },
    passport: {
      country: selectedCountry[0][0],
    },
  };
  // @ts-ignore
  if (selectedCountry[0][1].iso_3166_alpha2 === 'CH')
    return { config: swissConfig };
  // @ts-ignore
  if (selectedCountry[0][1].eu_member) return { config: euConfig };
  return { config: defaultConfig };
};
