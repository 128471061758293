import {
  Container,
  Box,
  Heading,
  Columns,
  Form,
  Content,
} from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';

import type { ResidenceStateTypes } from 'Components/Residence/ResidenceSlice';
import {
  replaceResidenceFields,
  getResidenceFields,
} from 'Components/Residence/ResidenceSlice';
import { getGlobalStatusCurrentTask } from 'Store/GlobalStatusSlice';
import {
  API_POST_RESIDENCE,
  STATUS_TASK_RESIDENCE,
  STATUS_INQUIRY_REQUEST,
} from 'Api/constants';
import { ROUTE_STEP_INCOME, ROUTE_STEP_INQUIRY } from 'Router/constants';
import { formResidenceSchema } from 'GlobalComponents/Form/ValidationSchemas';
import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import SelectList, {
  OPTIONS_TYPES,
} from 'GlobalComponents/Form/SelectList/SelectList';
import UploadFile from 'GlobalComponents/Form/UploadFile/UploadFile';
import { useStatusChecker } from 'helpers';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';
import { DOCUMENT_TYPES_RESIDENCE } from 'globalData';
import TextArea from 'GlobalComponents/Form/TextArea/TextArea';

const { Column } = Columns;
const { Help } = Form;

function Residence() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const defaultFields = useSelector(getResidenceFields);
  const globalStatus = useSelector(getGlobalStatusCurrentTask);

  const isInquiry = globalStatus === STATUS_INQUIRY_REQUEST;
  const isSubmitted = useStatusChecker(STATUS_TASK_RESIDENCE);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResidenceStateTypes>({
    defaultValues: defaultFields,
    mode: 'onChange',
    resolver: yupResolver(formResidenceSchema),
  });

  const selectedCountry = useWatch({ control, name: 'country' });

  const [{ loading: getLoading }, postResidenceData] = useAxios(
    {
      url: API_POST_RESIDENCE,
      method: 'POST',
    },
    { manual: true }
  );

  const isDisabled = !!getLoading || isSubmitted;
  const isCountryUS = selectedCountry === 'US';

  // Go next without sending data
  const handleGoNext = () => {
    navigate(`/${ROUTE_STEP_INCOME}`);
  };

  const handleSubmitResidence = async (data: any) => {
    try {
      const updatedData = {
        ...data,
        document: { ...data.document, files: [...data.document.files] },
      };
      await postResidenceData({
        data: updatedData,
      });
      dispatch(replaceResidenceFields(updatedData));

      navigate(isInquiry ? `/${ROUTE_STEP_INQUIRY}` : `/${ROUTE_STEP_INCOME}`);
    } catch (err) {
      handleError(err);
    }
  };

  const uploadHelpKey = () => (
    <Content>
      {t('residence.help')}
      <ul>
        <li>{t('residence.help_list_one')}</li>
        <li>{t('residence.help_list_two')}</li>
        <li>{t('residence.help_list_three')}</li>
      </ul>
    </Content>
  );

  return (
    <Container>
      <Heading>{t('residence.title')}</Heading>
      <Box>
        <Columns>
          <Column>
            <Controller
              control={control}
              name='address'
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label='residence.fields.address'
                  onChange={onChange}
                  value={value}
                  placeholder={t('global.form.textarea.address_placeholder')}
                  error={errors.address}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />
          </Column>
          <Column>
            <Controller
              control={control}
              name='country'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label='residence.fields.country'
                  onChange={onChange}
                  value={value}
                  error={errors.country}
                  isDisabled={isDisabled}
                  optionsType={OPTIONS_TYPES.COUNTRIES}
                />
              )}
            />
            {isCountryUS && (
              <Controller
                control={control}
                name='us_state'
                render={({ field: { onChange, value } }) => (
                  <SelectList
                    label='residence.fields.us_state'
                    onChange={onChange}
                    value={value}
                    error={errors.us_state}
                    isDisabled={isDisabled}
                    optionsType={OPTIONS_TYPES.US_STATE}
                  />
                )}
              />
            )}
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Controller
              control={control}
              name='document.type'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label={t('residence.fields.upload_document')}
                  onChange={onChange}
                  value={defaultFields?.document_types?.[0] || value}
                  error={errors.document?.type}
                  isDisabled={isDisabled}
                  selectOptions={DOCUMENT_TYPES_RESIDENCE}
                />
              )}
            />
            <Controller
              control={control}
              name='document.files'
              render={({ field: { onChange } }) => (
                <UploadFile
                  buttonLabel={t('residence.placeholders.upload_proof')}
                  isDisabled={isDisabled}
                  error={errors.document?.files}
                  onChange={onChange}
                  isSubmitted={isSubmitted}
                />
              )}
            />
          </Column>
        </Columns>
      </Box>
      <Help renderAs='div'>{uploadHelpKey()}</Help>
      <NavigationButtons
        onClickNext={
          isSubmitted ? handleGoNext : handleSubmit(handleSubmitResidence)
        }
        isLoading={!!getLoading}
      />
    </Container>
  );
}

export default withErrorBoundary(Residence, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
