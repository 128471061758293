import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import globalStatusReducer from 'Store/GlobalStatusSlice';
import PhoneVerificationReducer from 'Components/PhoneVerification/PhoneVerificationSlice';
import identityReducer from 'Components/Identity/IdentitySlice';
import residenceReducer from 'Components/Residence/ResidenceSlice';
import incomeReducer from 'Components/Income/IncomeSlice';
import originOfFundsReducer from 'Components/OriginOfFunds/OriginOfFundsSlice';
import uboReducer from 'Components/Ubo/UboSlice';
import inquiryOOFExplanationReducer from 'Components/Inquiry/InquiryOOFExplanation/InquiryOOFExplanationSlice';
import inquiryReducer from 'Components/Inquiry/InquirySlice';
import InquiryBeneficiaryReducer from 'Components/InquiryBeneficiary/InquiryBeneficiarySlice';
import CountriesDataReducer from './CountriesDataSlice';

const rootReducer = combineReducers({
  status: globalStatusReducer,
  identity: identityReducer,
  residence: residenceReducer,
  income: incomeReducer,
  originOfFunds: originOfFundsReducer,
  ubo: uboReducer,
  inquiry: inquiryReducer,
  inquiryOOFEplanation: inquiryOOFExplanationReducer,
  countriesData: CountriesDataReducer,
  phoneNumber: PhoneVerificationReducer,
  inquiryBeneficiary: InquiryBeneficiaryReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
