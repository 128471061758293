import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

// Redux State formatted
type KeyValue = { [key: string]: boolean };

type InquiryStatus = {
  allowed_submissions: string[];
  already_submitted: string[];
  question: string;
  order_beneficiary_declaration_orders?: any;
};

// Original status from API
export interface GlobalStatusTaskTypes {
  task: string;
  submitted: boolean;
}

export interface GlobalStatusStateTypes {
  current_task: string;
  initial_submission?: GlobalStatusTaskTypes[];
  inquiry?: InquiryStatus;
}

export interface StatusFormattedTypes {
  currentTask: string;
  initialSubmission?: KeyValue;
  inquiry?: InquiryStatus;
}

const initialState: StatusFormattedTypes = {
  currentTask: '',
};

export const GlobalStatusSlice = createSlice({
  name: 'GlobalStatus',
  initialState,
  reducers: {
    replaceGlobalStatus: (
      state,
      action: PayloadAction<GlobalStatusStateTypes>
    ) => {
      const {
        current_task: currentTask,
        initial_submission: initialSubmission,
        inquiry,
      } = action.payload;

      const formattedTasks = initialSubmission?.reduce(
        (acc, el) => ({ ...acc, [el.task]: el.submitted }),
        {}
      );

      const formattedState = inquiry
        ? {
            currentTask,
            inquiry,
          }
        : { currentTask, initialSubmission: formattedTasks };

      return formattedState;
    },
  },
});

export const { replaceGlobalStatus } = GlobalStatusSlice.actions;

export const getGlobalStatus = (state: RootState) => state.status;
export const getGlobalStatusCurrentTask = (state: RootState) =>
  state.status.currentTask;

export default GlobalStatusSlice.reducer;
