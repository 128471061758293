import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

export interface IncomeStateTypes {
  [fieldName: string]: any;
  annual_income: {
    currency: string;
    value: string;
  };
  expected_yearly_trade_volume: {
    currency: string;
    value: string;
  };
  industry: string;
  net_worth: {
    currency: string;
    value: string;
  };
  occupation: string;
  is_high_risk?: boolean;
}

const initialState: IncomeStateTypes = {
  annual_income: {
    currency: 'CHF',
    value: '',
  },
  expected_yearly_trade_volume: {
    currency: 'CHF',
    value: '',
  },
  net_worth: {
    currency: 'CHF',
    value: '',
  },
  industry: '',
  occupation: '',
};

export const incomeSlice = createSlice({
  name: 'income',
  initialState,
  reducers: {
    replaceIncomeFields: (state, action: PayloadAction<IncomeStateTypes>) =>
      action.payload,
  },
});

export const { replaceIncomeFields } = incomeSlice.actions;

export const getIncomeFields = (state: RootState) => state.income;

export default incomeSlice.reducer;
