import { Container, Hero, Heading, Block } from 'react-bulma-components';

import packageInfo from '../../../package.json';

const VersionPage = () => (
  <Container>
    <Hero>
      <Hero.Body textAlign='center'>
        <Block>
          <Heading>Version</Heading>
          <Heading subtitle>{packageInfo.version}</Heading>
        </Block>
      </Hero.Body>
    </Hero>
  </Container>
);

export default VersionPage;
