import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

// enum InquiryBeneficiaryTypeTypes {
//   Self = 'account_owner',
//   Person = 'person',
//   Organization = 'organization',
// }

export interface InquiryBeneficiaryField {
  orderId?: string;
  id?: string;
}
export interface InquiryBeneficiaryEntryPerson extends InquiryBeneficiaryField {
  first_name: string;
  last_name: string;
  country_of_residence?: string;
}

export interface InquiryBeneficiaryEntryOrganization
  extends InquiryBeneficiaryField {
  name: string;
  country: string;
}

export interface InquiryBeneficiaryOrderTypes {
  created_at: string;
  executed_at: string;
  external_order_id: string;
  input_amount: {
    currency: string;
    value: string;
  };
  output_amount: {
    currency: string;
    value: string;
  };
  output_crypto_address: string;
  beneficiaries?: [
    InquiryBeneficiaryEntryPerson | InquiryBeneficiaryEntryOrganization
  ];
}

// const initialState: any = {
//   beneficiary: [
//     [
//       'toto',
//       [
//         {
//           created_at: '',
//           executed_at: '',
//           external_order_id: '',
//           input_amount: {
//             currency: '',
//             value: '',
//           },
//           output_amount: {
//             currency: '',
//             value: '',
//           },
//           output_crypto_address: '',
//         },
//       ],
//     ],
//   ],
// };

const initialState: any = {
  beneficiary: [],
};

export const inquiryBeneficiarySlice = createSlice({
  name: 'inquiry_beneficiary',
  initialState,
  reducers: {
    replaceInquiryBeneficiaryFields: (
      state,
      action: PayloadAction<
        | InquiryBeneficiaryEntryPerson
        | InquiryBeneficiaryEntryOrganization
        | any
      >
    ) => action.payload,
    addInquiryBeneficiaryFields: (state, action: PayloadAction<any>) => {
      const newState = { beneficiary: [...state.beneficiary, action.payload] };
      return newState;
    },
  },
});

export const { replaceInquiryBeneficiaryFields, addInquiryBeneficiaryFields } =
  inquiryBeneficiarySlice.actions;

export const getInquiryBeneficiaryFields = (state: RootState) =>
  state.inquiryBeneficiary.beneficiary;
export const getInquiryBeneficiaryEntry = (state: RootState) =>
  state.inquiryBeneficiary[1];

export default inquiryBeneficiarySlice.reducer;
