export const ROUTE_STEP_PHONE = 'phone';
export const ROUTE_STEP_PHONE_SMS_CODE = 'sms-verification';
export const ROUTE_STEP_IDENTITY = 'identity';
export const ROUTE_STEP_RESIDENCE = 'residence';
export const ROUTE_STEP_INCOME = 'income';
export const ROUTE_STEP_OOF = 'origins-of-funds';
export const ROUTE_STEP_UBO = 'ubo';
export const ROUTE_STEP_SUCCESS = 'success';
export const ROUTE_STEP_INQUIRY = 'inquiry';
export const ROUTE_STEP_INQUIRY_DOCUMENTS = 'inquiry-documents';
export const ROUTE_STEP_INQUIRY_BENEFICIARY = 'inquiry-beneficiary';
export const ROUTE_STEP_INQUIRY_OOF_EXPLANATION = 'inquiry-oof';
export const ROUTE_STEP_ACCOUNT_CHOICE = 'account-type';
