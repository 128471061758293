import React, { useState } from 'react';
import { Form, Tag } from 'react-bulma-components';
import type { FieldValues } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { convertFilesToBase64, HTMLElementEvent } from 'helpers';

const { Field, Control, Label, InputFile, Help } = Form;

interface UploadFileTyes {
  onChange: (event: HTMLElementEvent<HTMLInputElement> | any) => void;
  error?: FieldValues | undefined | null;
  isDisabled?: boolean;
  placeholder?: string;
  multiple?: boolean;
  label?: string;
  buttonLabel?: string;
  isSubmitted?: boolean;
}

const UploadFile = React.forwardRef<any, UploadFileTyes>(
  (
    {
      onChange,
      error,
      isDisabled,
      placeholder,
      label,
      multiple = false,
      isSubmitted,
      buttonLabel,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
  ) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<any[]>();

    const handleChange = async (event: any) => {
      const { target } = event;

      const extractedFiles = Object.entries(target.files).reduce(
        (acc, [, file]: any): any => [...acc, file],
        []
      );

      setFiles(extractedFiles);

      const convertedFiles = await convertFilesToBase64(extractedFiles);

      target.value = ''; // Reset to null to allow selecting again the same element
      return onChange(convertedFiles);
    };

    const handleRemoveFile = async (index: number) => {
      const updatedList = files?.filter((file, i) => i !== index);
      const convertedFiles = await convertFilesToBase64(updatedList);
      setFiles(updatedList);

      return onChange(convertedFiles);
    };

    const inputColors = () => {
      if (error) {
        return 'danger';
      }
      if (isSubmitted) {
        return 'success';
      }
      if (isDisabled) {
        return '';
      }
      return 'primary';
    };

    const renderIcon = () =>
      isSubmitted ? (
        <FontAwesomeIcon icon='circle-check' />
      ) : (
        <FontAwesomeIcon icon='upload' />
      );

    const renderInputLabel = () => {
      const defaultLabel = multiple
        ? t('global.form.upload_file.upload_multiple')
        : t('global.form.upload_file.upload_single');
      return isSubmitted
        ? t('global.form.upload_file.upload_complete')
        : defaultLabel;
    };

    return (
      <Field>
        {label && (
          <Label>
            <span style={{ color: 'red' }}>*&nbsp;</span>
            {label}
          </Label>
        )}
        <Control>
          <InputFile
            inputProps={{
              // @ts-ignore
              disabled: isDisabled,
              accept: '.pdf,.csv,.txt,image/*',
              multiple,
            }}
            label={buttonLabel || renderInputLabel()}
            onChange={event => handleChange(event)}
            filename={isSubmitted ? '' : placeholder}
            color={inputColors()}
            icon={renderIcon()}
            my={3}
          />

          {files &&
            files.map(({ name }, index) => (
              <Tag.Group key={name} gapless my={1}>
                <Tag color='success'>{name}</Tag>
                <Tag remove onClick={() => handleRemoveFile(index)} />
              </Tag.Group>
            ))}

          {error && (
            <Help className='has-text-danger'>
              {error.message ? error.message : error}
            </Help>
          )}
        </Control>
      </Field>
    );
  }
);

export default UploadFile;
