import { Fragment, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { groupBy } from 'lodash';
import {
  Container,
  Heading,
  Form,
  Modal,
  Button,
} from 'react-bulma-components';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { API_POST_INQUIRY_BENEFICIARY } from 'Api/constants';
import { getGlobalStatus } from 'Store/GlobalStatusSlice';

import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import { ROUTE_STEP_INQUIRY } from 'Router/constants';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

import InquiryBeneficiaryOrder from 'Components/InquiryBeneficiary/InquiryBeneficiaryOrder';
import { replaceInquiryState } from 'Components/Inquiry/InquirySlice';

import './InquiryBeneficiary.scss';

const { Help } = Form;

function InquiryBeneficiary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const { inquiry } = useSelector(getGlobalStatus);

  const [orderToSupport, setOrderToSupport] = useState<any>([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'beneficiary',
  });

  const [{ loading: postInquiryBeneficiaryLoading }, postInquiryBeneficiary] =
    useAxios(
      {
        url: API_POST_INQUIRY_BENEFICIARY,
        method: 'POST',
      },
      { manual: true }
    );

  // const isDisabled = !!getLoading;

  // Submitting step and go next
  const handleSubmitInquiryBeneficiary = async () => {
    try {
      await postInquiryBeneficiary({
        data: { finalized: true },
      });
      // dispatch(replaceInquiryBeneficiaryFields(updatedData));
      navigate(`/${ROUTE_STEP_INQUIRY}`);
    } catch (err) {
      handleError(err);
    }
  };

  const ordersFormatted = groupBy(
    inquiry?.order_beneficiary_declaration_orders,
    'output_crypto_address'
  );
  const ordersArray = Object.entries(ordersFormatted);

  const renderHelpTexts = () => (
    <>
      <Help renderAs='div'>
        <ul>
          <li>
            <sup>(1)</sup>
            {t('inquiry_beneficiary.help.1')}
          </li>
          <li>
            <sup>(2)</sup>
            {t('inquiry_beneficiary.help.2')}
          </li>
          <li>
            <sup>(3)</sup>
            {t('inquiry_beneficiary.help.3')}
          </li>
          <li>
            <sup>(4)</sup>
            {t('inquiry_beneficiary.help.4')}
          </li>
          <li>
            <sup>(5)</sup>
            {t('inquiry_beneficiary.help.5')}
          </li>
          <li>
            <sup>(6)</sup>
            {t('inquiry_beneficiary.help.6')}
          </li>
        </ul>
      </Help>
    </>
  );

  useEffect(() => {
    dispatch(
      replaceInquiryState({
        answer: `Liste des ordres a envoyer au support pour vérification :\n\n${orderToSupport
          .toString()
          .split(',')
          .join('\n')}`,
      })
    );
  }, [orderToSupport]);

  return (
    <Container>
      <Heading>{t('inquiry_beneficiary.step_name')}</Heading>
      <Heading subtitle>{t('inquiry_beneficiary.title')}</Heading>
      {ordersArray.map((wallet, i) => (
        <Fragment key={wallet[0]}>
          <InquiryBeneficiaryOrder
            wallet={wallet}
            indexOfOrder={wallet[0]}
            indexArray={i}
            append={append}
            update={update}
            remove={remove}
            fields={fields}
            setOrderToSupport={setOrderToSupport}
            orderToSupport={orderToSupport}
          />
        </Fragment>
      ))}
      {renderHelpTexts()}

      <Modal
        show={isConfirmationModalOpen}
        closeOnEsc
        onClose={() => setIsConfirmationModalOpen(false)}>
        <Modal.Card className='InquiryBeneficiary__modal-card'>
          <Modal.Card.Body>
            {t('inquiry_beneficiary.modal_confirmation.body')}
          </Modal.Card.Body>
          <Modal.Card.Footer>
            <Button
              color='success'
              onClick={handleSubmit(handleSubmitInquiryBeneficiary)}>
              {t('global.form.submit')}
            </Button>
            <Button
              color='danger'
              onClick={() => setIsConfirmationModalOpen(false)}>
              {t('global.form.cancel')}
            </Button>
          </Modal.Card.Footer>
        </Modal.Card>
      </Modal>

      <NavigationButtons
        // onClickNext={handleSubmit(handleSubmitInquiryBeneficiary)}
        onClickNext={() => setIsConfirmationModalOpen(true)}
        isLoading={!!postInquiryBeneficiaryLoading}
        // isNextDisabled={fields.length === 0}
        nextLabel='global.form.submit'
        nextColor='success'
      />
    </Container>
  );
}

export default withErrorBoundary(InquiryBeneficiary, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
