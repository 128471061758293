import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Form } from 'react-bulma-components';

import {
  getCountriesDataStore,
  getStatesOfUs,
  getCountriesCallingCodes,
} from 'Store/CountriesDataSlice';

const { Field, Control, Label, Help, Select } = Form;

export const OPTIONS_TYPES = {
  COUNTRIES: 'COUNTRIES',
  US_STATE: 'US_STATE',
  CALLING_CODES: 'CALLING_CODES',
};

type SelectCustomOptionType = { name: string; key: string };

interface InputPropsTypes {
  onChange: any;
  onBlur?: any;
  value: any;
  label?: string;
  error?: any;
  isDisabled?: boolean;
  selectOptions?: SelectCustomOptionType[];
  optionsType?: string;
  isRequired?: boolean;
}

const SelectList = React.forwardRef<any, InputPropsTypes>(
  (
    {
      onChange,
      onBlur,
      value,
      label,
      error,
      isDisabled,
      selectOptions,
      optionsType,
      isRequired = true,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
  ) => {
    const { t } = useTranslation();

    const renderCountriesOptions = () => {
      const countriesList = useSelector(getCountriesDataStore);

      const list = Object.entries(countriesList).sort((country1, country2) =>
        country1[1].name.localeCompare(country2[1].name)
      );

      const updatedList = [
        [
          '',
          {
            name: t('global.form.list.default.country'),
          },
        ],
        ...list,
      ];

      return updatedList.map(([key, { name, iso_3166_alpha2 }]: any) => (
        <option key={key} value={iso_3166_alpha2}>
          {name}
        </option>
      ));
    };

    const renderUsStatesOptions = () => {
      const usStateList = useSelector(getStatesOfUs);

      return usStateList?.map(({ code, name }: any) => (
        <option key={code} value={code}>
          {name}
        </option>
      ));
    };

    const renderCallingCodesOptions = () => {
      const callingCodes = useSelector(getCountriesCallingCodes);

      return callingCodes?.map(({ country_code, name, flag, iso3 }: any) => (
        <option key={iso3} value={country_code}>
          {name} {flag} {country_code || null}
        </option>
      ));
    };

    const renderCustomOptions = () =>
      selectOptions?.map(({ name, key }) => (
        <option key={key} value={key}>
          {t(name)}
        </option>
      ));

    const renderOptions = () => {
      switch (optionsType) {
        case OPTIONS_TYPES.COUNTRIES:
          return renderCountriesOptions();
        case OPTIONS_TYPES.US_STATE:
          return renderUsStatesOptions();
        case OPTIONS_TYPES.CALLING_CODES:
          return renderCallingCodesOptions();
        default:
          return renderCustomOptions();
      }
    };

    return (
      <Field>
        {label && (
          <Label>
            {isRequired && <span style={{ color: 'red' }}>*&nbsp;</span>}
            {t(`${label}`)}
          </Label>
        )}
        <Control>
          <Select
            value={value}
            disabled={isDisabled}
            onChange={onChange}
            onBlur={onBlur}
            color={error ? 'danger' : ''}>
            {renderOptions()}
          </Select>
        </Control>
        {error && (
          <Help className='has-text-danger'>
            {error.message ? error.message : error}
          </Help>
        )}
      </Field>
    );
  }
);

export default SelectList;
