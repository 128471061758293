import { useState } from 'react';
import {
  Container,
  Box,
  Heading,
  Block,
  Message,
  Notification,
  Button,
} from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks';

import { getGlobalStatus } from 'Store/GlobalStatusSlice';

import {
  getInquiryState,
  replaceInquiryState,
} from 'Components/Inquiry/InquirySlice';
import TextArea from 'GlobalComponents/Form/TextArea/TextArea';
import { stepsMappingToRoute, stepsMappingToName } from 'helpers';
import { API_POST_INQUIRY } from 'Api/constants';
import { ROUTE_STEP_SUCCESS } from 'Router/constants';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const shortid = require('shortid');

const Inquiry = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const { inquiry } = useSelector(getGlobalStatus);
  const defaultValues = useSelector(getInquiryState);
  const [answerValue, setAnswerValue] = useState<string>('');

  const [{ loading: postInquiryLoading }, postInquiryData] = useAxios(
    {
      url: API_POST_INQUIRY,
      method: 'POST',
    },
    { manual: true }
  );

  const handleSubmitInquiry = async () => {
    try {
      await postInquiryData({
        data: { answer: answerValue || t('inquiry.no_answer') },
      });
      navigate(`/${ROUTE_STEP_SUCCESS}`);
    } catch (err) {
      handleError(err);
    }
  };

  const handleUpdateAnswer = (value: string) => {
    setAnswerValue(value);
    dispatch(replaceInquiryState({ answer: value }));
  };

  const renderRemainingInquiryTasks = () =>
    inquiry?.allowed_submissions.map(step => {
      const route = stepsMappingToRoute(step);
      const stepName = stepsMappingToName(step);

      return (
        <Block key={shortid.generate()}>
          <Link to={postInquiryLoading ? '/#' : `/${route}`}>
            <Notification color='warning-inquiry' light={postInquiryLoading}>
              {t('inquiry.tasks_title', { step: stepName })}
              <span style={{ float: 'right' }}>
                <FontAwesomeIcon icon='chevron-right' color='blue' />
              </span>
            </Notification>
          </Link>
        </Block>
      );
    });

  const renderSubmittedInquiryTasks = () =>
    inquiry?.already_submitted.map(step => {
      const name = stepsMappingToName(step);

      return (
        <Block key={shortid.generate()}>
          <Notification color='success-inquiry' light>
            {name} - {t('inquiry.completed')}
            <span style={{ float: 'right' }}>
              <FontAwesomeIcon icon='circle-check' color='green' />
            </span>
          </Notification>
        </Block>
      );
    });

  const hasSubmittedAllSteps =
    inquiry!.already_submitted.length > inquiry!.allowed_submissions.length;
  const labelProgressText = hasSubmittedAllSteps
    ? t('inquiry.no_remaining_steps')
    : t('inquiry.remaining_steps', {
        steps: inquiry?.allowed_submissions.join(', '),
      });
  let submitButtonText: string;
  if (postInquiryLoading) {
    submitButtonText = t('global.loading');
  } else if (hasSubmittedAllSteps) {
    submitButtonText = t('global.form.submit');
  } else {
    submitButtonText = t('global.disabled');
  }

  return (
    <Container>
      <Heading>{t('inquiry.heading')}</Heading>
      <Heading subtitle>{t('inquiry.subheading')}</Heading>
      <Box>
        <Block my='6'>
          <Message color='info'>
            <Message.Header>{t('inquiry.message.title')}</Message.Header>
            <Message.Body
              className='inquiry-html'
              dangerouslySetInnerHTML={{ __html: inquiry!.question }}
            />
          </Message>
        </Block>
        <Block my='6'>
          {!inquiry?.allowed_submissions.length ? (
            <Heading subtitle>{t('inquiry.tasks_completed')}</Heading>
          ) : (
            <>
              <Heading subtitle>{t('inquiry.tasks_heading')}</Heading>
              {renderRemainingInquiryTasks()}
            </>
          )}
        </Block>
        {!!inquiry?.already_submitted.length && (
          <>
            <Heading subtitle>{t('inquiry.tasks_submitted')}</Heading>
            {renderSubmittedInquiryTasks()}
          </>
        )}
        <Block my='6'>
          <Message
            color={hasSubmittedAllSteps ? 'success-inquiry' : 'danger-inquiry'}
          >
            <Message.Body>{labelProgressText}</Message.Body>
          </Message>
        </Block>
        <Block my='6'>
          <TextArea
            label='inquiry.answer'
            onChange={(e: any) => handleUpdateAnswer(e.target.value)}
            value={defaultValues.answer || answerValue}
            placeholder={t('global.form.textarea.max', { max: 250 })}
            maxLength={250}
            isDisabled={postInquiryLoading}
            optionalLabel='global.optional'
          />
        </Block>
        <Block>
          <Button
            onClick={() => handleSubmitInquiry()}
            color='submit-inquiry'
            disabled={postInquiryLoading || !hasSubmittedAllSteps}
          >
            {submitButtonText}
          </Button>
        </Block>
      </Box>
    </Container>
  );
};

export default withErrorBoundary(Inquiry, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
