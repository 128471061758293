import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bulma-components';

const { Field, Control, Label, Help, Input } = Form;

interface InputPropsTypes {
  onChange: any;
  onBlur?: any;
  value: any;
  type?: string;
  label?: string;
  error?: any;
  isDisabled?: boolean;
  placeholder?: string;
  name?: any;
  inputRef?: any;
}

const InputText = React.forwardRef<any, InputPropsTypes>(
  (
    {
      onChange,
      onBlur,
      value,
      type,
      label,
      error,
      isDisabled,
      placeholder,
      name,
      inputRef,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
  ) => {
    const { t } = useTranslation();

    // remove placeholders
    const updatedPlaceholder = placeholder || '';

    return (
      <Field>
        {label && (
          <Label>
            <span style={{ color: 'red' }}>*&nbsp;</span>
            {t(`${label}`)}
          </Label>
        )}
        <Control>
          <Input
            name={name}
            domRef={inputRef}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={t(`${updatedPlaceholder}`)}
            color={error ? 'danger' : ''}
            disabled={isDisabled}
          />
        </Control>
        {error && (
          <Help className='has-text-danger'>
            {error.message ? t(error.message) : error}
          </Help>
        )}
      </Field>
    );
  }
);

export default InputText;
