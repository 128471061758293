import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import { BityApiClient, BityApiClientInterface } from '@bity/api';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import {
  BITY_API_EXCHANGE_URL,
  BITY_API_AUTHORIZATION_URL,
  BITY_API_TOKEN_URL,
  BITY_API_REDIRECT_URL,
  BITY_API_CLIENT_ID,
  BITY_API_SCOPE_URL,
  BITY_API_SCOPE_KYC,
} from 'Api/config';
import { store, persistor } from 'Store/store';
import App from './Components/App/App';

import './i18n';
import './styles/styles.scss';

library.add(fas);

const root = ReactDOM.createRoot(document.getElementById('root'));

const bityClient: BityApiClientInterface = new BityApiClient({
  exchangeApiUrl: BITY_API_EXCHANGE_URL,
  clientId: BITY_API_CLIENT_ID,
  oauthConfig: {
    authorizationUrl: BITY_API_AUTHORIZATION_URL,
    tokenUrl: BITY_API_TOKEN_URL,
    clientId: BITY_API_CLIENT_ID,
    redirectUrl: BITY_API_REDIRECT_URL,
    scopes: [`${BITY_API_SCOPE_URL}/${BITY_API_SCOPE_KYC}`],
    onAccessTokenExpiry: refreshAccessToken => refreshAccessToken(),
    // onInvalidGrant: () => {
    //   console.log(
    //     'Auth grant or refresh token expired! Get a new authorization grant code.'
    //   );
    //   bityClient.fetchAuthorizationCode();
    //   // Normally call refreshAuthCodeOrRefreshToken(); which calls fetchAuthorizationCode().
    //   // But you can do whatever you want - maybe you want a user to press
    //   // a button that executes fetchAuthorizationCode() instead.
    // },
  },
});

bityClient
  .isReturningFromAuthServer()
  .then((hasReturned: boolean): Promise<void> => {
    if (hasReturned) {
      return bityClient.getAccessToken().then(() => {
        location.replace('/');
      });
    }
    bityClient.setOAuthActive(true);
    return Promise.resolve();
  })
  .catch(e => {
    console.error(e);
  });

const Loading = () => <h1>LOADING...</h1>;

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <App bityClient={bityClient} />
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
