import { yupResolver } from '@hookform/resolvers/yup';
import type { InquiryOOFExplanationDocumentsTypes } from 'Components/Inquiry/InquiryOOFExplanation/InquiryOOFExplanationSlice';
import { initialDocument } from 'Components/Inquiry/InquiryOOFExplanation/InquiryOOFExplanationSlice';
import TextArea from 'GlobalComponents/Form/TextArea/TextArea';
import UploadFile from 'GlobalComponents/Form/UploadFile/UploadFile';
import { formInquiryOOFExplanationModalSchema } from 'GlobalComponents/Form/ValidationSchemas';
import { Heading, Button } from 'react-bulma-components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface InquiryOOFEplanationFormPropsTypes {
  index: any;
  isDisabled?: boolean;
  append: any;
  field: any;
  onModalClose?: any;
}

const InquiryOOFEplanationForm = ({
  append,
  index,
  isDisabled,
  field,
  onModalClose,
}: InquiryOOFEplanationFormPropsTypes) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InquiryOOFExplanationDocumentsTypes>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initialDocument,
    resolver: yupResolver(formInquiryOOFExplanationModalSchema),
  });

  const handleSubmitForm = (data: any) => {
    append(data);
    return onModalClose();
  };

  return (
    <>
      <Heading subtitle>
        {t('inquiry.steps.OOFExplanation.fields.document_title', {
          number: index,
        })}
      </Heading>
      <Controller
        control={control}
        name='document.files'
        render={({ field: { onChange } }) => (
          <UploadFile
            error={errors && errors.document?.files}
            label={t('global.file_document_upload')}
            onChange={onChange}
            multiple
            isDisabled={isDisabled}
            isSubmitted={isDisabled}
          />
        )}
      />
      <Controller
        control={control}
        name='description'
        render={({ field: { onChange, value } }) => (
          <TextArea
            error={errors && errors.description}
            label='inquiry.steps.OOFExplanation.fields.document_description'
            onChange={onChange}
            value={field.description || value}
            maxLength={450}
            isDisabled={isDisabled}
            required
          />
        )}
      />
      {onModalClose && (
        <Button
          color='primary'
          onClick={handleSubmit(handleSubmitForm)}
          disabled={isDisabled}>
          Submit
        </Button>
      )}
    </>
  );
};

export default InquiryOOFEplanationForm;
