import { Heading, Columns, Form, Button } from 'react-bulma-components';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { formUboEntrySchema } from 'GlobalComponents/Form/ValidationSchemas';
import { isPepData } from 'helpers';

import type { UboEntry } from 'Components/Ubo/UboSlice';
import { getUboEntry } from 'Components/Ubo/UboSlice';
import InputText from 'GlobalComponents/Form/InputText/InputText';
import SelectList, {
  OPTIONS_TYPES,
} from 'GlobalComponents/Form/SelectList/SelectList';
import UploadFile from 'GlobalComponents/Form/UploadFile/UploadFile';
import TextArea from 'GlobalComponents/Form/TextArea/TextArea';
import RadioButton from 'GlobalComponents/Form/RadioButton/RadioButton';

import { DOCUMENT_IDENTITY_TYPES } from 'globalData';

const { Field } = Form;
const { Column } = Columns;

interface UboFormPropsTypes {
  index: any;
  isDisabled: boolean;
  append: any;
  field: any;
  onModalClose?: any;
}

const UboForm = ({
  append,
  index,
  isDisabled,
  field,
  onModalClose,
}: UboFormPropsTypes) => {
  const { t } = useTranslation();
  const defaultEntries = useSelector(getUboEntry);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UboEntry>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultEntries,
    resolver: yupResolver(formUboEntrySchema),
  });

  const selectedCountry = useWatch({
    control,
    name: 'country',
  });

  const selectedNationality = useWatch({
    control,
    name: 'nationality',
  });

  const isPep = useWatch({
    control,
    name: 'is_pep',
  });

  const documentType = useWatch({
    control,
    name: 'identity_document.type',
  });

  const isCountryUS = selectedCountry === 'US';

  const handleSubmitForm = (data: any) => {
    append(data);
    return onModalClose();
  };

  const headingTranslation = !onModalClose
    ? t('ubo.subtitle_multiple', { number: index + 1 })
    : t('ubo.subtitle');

  const pepTranslation = () => (
    <Trans i18nKey='global.form.is_pep_self'>
      Iam a
      <a
        href='https://intercom.help/bity-help-center/en/articles/1505730-what-is-a-pep'
        target='_blank'
        rel='noreferrer'>
        politically exposed person
      </a>
      or an immediate family member of, or closely related to, such a person.
    </Trans>
  );

  return (
    <Field>
      <Heading subtitle>{headingTranslation}</Heading>
      <Columns>
        <Column>
          <Controller
            control={control}
            name='first_name'
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <InputText
                name={name}
                inputRef={ref}
                label='identity.fields.first_name'
                onChange={onChange}
                onBlur={onBlur}
                value={field.first_name || value}
                error={errors && errors.first_name}
                isDisabled={isDisabled}
              />
            )}
          />
        </Column>
        <Column>
          <Controller
            control={control}
            name='last_name'
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                label='identity.fields.last_name'
                onChange={onChange}
                onBlur={onBlur}
                value={field.last_name || value}
                error={errors && errors.last_name}
                isDisabled={isDisabled}
              />
            )}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Controller
            control={control}
            name='date_of_birth'
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                label='identity.fields.date_of_birth'
                type='date'
                onChange={onChange}
                onBlur={onBlur}
                value={field.date_of_birth || value}
                error={errors && errors.date_of_birth}
                isDisabled={isDisabled}
              />
            )}
          />
        </Column>
        <Column>
          <Controller
            control={control}
            name='nationality'
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectList
                label='identity.fields.nationality'
                onChange={onChange}
                onBlur={onBlur}
                value={field.nationality || value}
                error={errors && errors.nationality}
                isDisabled={isDisabled}
                optionsType={OPTIONS_TYPES.COUNTRIES}
              />
            )}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Controller
            control={control}
            name='address'
            render={({ field: { onChange, value } }) => (
              <TextArea
                label='residence.fields.address'
                onChange={onChange}
                value={field.address || value}
                placeholder={t('global.form.textarea.address_placeholder')}
                error={errors && errors.address}
                isDisabled={isDisabled}
                required
              />
            )}
          />
        </Column>
        <Column>
          <Controller
            control={control}
            name='country'
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectList
                label='residence.fields.country'
                onChange={onChange}
                onBlur={onBlur}
                value={field.country || value}
                error={errors && errors.country}
                isDisabled={isDisabled}
                optionsType={OPTIONS_TYPES.COUNTRIES}
              />
            )}
          />
          {isCountryUS && (
            <Controller
              control={control}
              name='us_state'
              render={({ field: { onChange, onBlur, value } }) => (
                <SelectList
                  label='residence.fields.us_state'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={field.us_state || value}
                  error={errors && errors.us_state}
                  optionsType={OPTIONS_TYPES.US_STATE}
                  isDisabled={isDisabled || !isCountryUS}
                />
              )}
            />
          )}
        </Column>
      </Columns>
      <Form.Label>
        <span style={{ color: 'red' }}>*&nbsp;</span>
        {pepTranslation()}
      </Form.Label>
      <RadioButton
        control={control}
        options={isPepData()}
        name='is_pep'
        isDisabled={isDisabled}
        error={errors.is_pep}
        isBool
        defaultChecked={field.is_pep}
      />

      {isPep && (
        <Controller
          control={control}
          name='pep_explanation'
          render={({ field: { onChange, value } }) => (
            <TextArea
              label='global.form.pep_description'
              onChange={onChange}
              value={value}
              error={errors.pep_explanation}
              isDisabled={isDisabled}
              required
            />
          )}
        />
      )}
      <Controller
        control={control}
        name='identity_document.type'
        render={({ field: { onChange, value } }) => (
          <SelectList
            label={t('global.file_document_upload')}
            onChange={onChange}
            value={value}
            error={errors.identity_document?.type}
            isDisabled={isDisabled}
            selectOptions={DOCUMENT_IDENTITY_TYPES(selectedNationality)}
          />
        )}
      />
      <Controller
        control={control}
        name='identity_document.files'
        render={({ field: { onChange } }) => (
          <UploadFile
            error={errors && errors.identity_document?.files}
            label={t('global.id_document_upload')}
            onChange={onChange}
            multiple
            isDisabled={isDisabled || !documentType}
            isSubmitted={isDisabled}
          />
        )}
      />
      {onModalClose && (
        <Button
          color='primary'
          onClick={handleSubmit(handleSubmitForm)}
          disabled={isDisabled}>
          Submit
        </Button>
      )}
    </Field>
  );
};

export default UboForm;
