import { Level, Button } from 'react-bulma-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './NavigationButtons.scss';

type NavigationButtonsTypes = {
  isNextDisabled?: boolean;
  isNextDisplayed?: boolean;
  isBackDisplayed?: boolean;
  isSecondaryButton?: boolean;
  isSecondaryButtonDisabled?: boolean;
  secondaryLabel?: string;
  secondaryColor?: string;
  isLoading?: boolean;
  nextLabel?: string;
  nextColor?: string;
  onClickNext?: () => void;
  onClickSecondary?: () => void;
};

const NavigationButtons = ({
  isNextDisplayed = true,
  isBackDisplayed = true,
  isNextDisabled = false,
  isSecondaryButton = false,
  isSecondaryButtonDisabled = false,
  isLoading = false,
  nextLabel,
  nextColor,
  secondaryLabel,
  secondaryColor,
  onClickNext,
  onClickSecondary,
}: NavigationButtonsTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const nextTranslationLabel = nextLabel || 'navigation.next';
  const secondaryTranslationLabel = secondaryLabel || 'navigation.next';

  return (
    <Level className='NavigationButtons'>
      <Level.Side align='left'>
        {isBackDisplayed && (
          <Level.Item>
            <Button
              size='medium'
              disabled={isLoading}
              onClick={() => navigate(-1)}>
              {t('navigation.back')}
            </Button>
          </Level.Item>
        )}
      </Level.Side>
      {isNextDisplayed && (
        <Level.Side align='right'>
          {isSecondaryButton && (
            <Level.Item>
              <Button
                color={secondaryColor || 'warning'}
                size='medium'
                disabled={isSecondaryButtonDisabled || isLoading}
                loading={isLoading}
                onClick={onClickSecondary}>
                {isLoading ? t('global.loading') : t(secondaryTranslationLabel)}
              </Button>
            </Level.Item>
          )}
          <Level.Item>
            <Button
              color={nextColor || 'primary'}
              size='medium'
              disabled={isNextDisabled || isLoading}
              loading={isLoading}
              onClick={onClickNext}>
              {isLoading ? t('global.loading') : t(nextTranslationLabel)}
            </Button>
          </Level.Item>
        </Level.Side>
      )}
    </Level>
  );
};

export default NavigationButtons;
