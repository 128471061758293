import { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Heading,
  Columns,
  Button,
  Block,
  Notification,
  Form,
} from 'react-bulma-components';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import * as Onfido from 'onfido-sdk-ui';
import _ from 'lodash';
import i18next from 'i18next';

import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';

import {
  getIdentityFields,
  replaceIdentityFields,
} from 'Components/Identity/IdentitySlice';
import type { IdentityStateTypes } from 'Components/Identity/IdentitySlice';
import { getGlobalStatusCurrentTask } from 'Store/GlobalStatusSlice';
import { getCountriesDataStore } from 'Store/CountriesDataSlice';

import {
  formIdentitySchema,
  formIdentityOnfidoSchema,
} from 'GlobalComponents/Form/ValidationSchemas';
import { ROUTE_STEP_RESIDENCE, ROUTE_STEP_INQUIRY } from 'Router/constants';
import {
  API_POST_IDENTITY,
  STATUS_TASK_IDENTITY,
  API_POST_ONFIDO_INIT,
  STATUS_INQUIRY_REQUEST,
} from 'Api/constants';

import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import InputText from 'GlobalComponents/Form/InputText/InputText';
import SelectList, {
  OPTIONS_TYPES,
} from 'GlobalComponents/Form/SelectList/SelectList';
import UploadFile from 'GlobalComponents/Form/UploadFile/UploadFile';
import TextArea from 'GlobalComponents/Form/TextArea/TextArea';
import RadioButton from 'GlobalComponents/Form/RadioButton/RadioButton';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

import { useStatusChecker, isPepSelfData } from 'helpers';
import { DOCUMENT_IDENTITY_TYPES, useOnfidoConfigSelection } from 'globalData';

const { Column } = Columns;

const onfidoContainerId = 'onfido-sdk-wrapper';

function Identity() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const defaultFields = useSelector(getIdentityFields);
  const countryList = useSelector(getCountriesDataStore);

  const [onfidoToken, setOnfidoToken] = useState<string>('');
  const [onfidoDisabled, setOnfidoDisabled] = useState<boolean>(false);
  const [onfidoCompleted, setOnfidoCompleted] = useState<boolean>(false);
  const globalStatus = useSelector(getGlobalStatusCurrentTask);

  const isInquiry = globalStatus === STATUS_INQUIRY_REQUEST;

  const isSubmitted = useStatusChecker(STATUS_TASK_IDENTITY);

  const validationSchema = onfidoDisabled
    ? formIdentitySchema
    : formIdentityOnfidoSchema;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IdentityStateTypes>({
    defaultValues: defaultFields,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [{ loading: getLoading }, postIdentityData] = useAxios(
    {
      url: API_POST_IDENTITY,
      method: 'POST',
    },
    { manual: true }
  );

  const isDisabled = !!getLoading || isSubmitted;

  // Go next without sending data
  const handleGoNext = () => {
    navigate(isInquiry ? `/${ROUTE_STEP_INQUIRY}` : `/${ROUTE_STEP_RESIDENCE}`);
  };

  // Submitting step and go next
  const handleSubmitIdentity = async (data: any) => {
    try {
      const updatedData = onfidoDisabled
        ? data
        : _.omit(data, ['identity_document']);

      await postIdentityData({
        data: updatedData,
      });
      dispatch(replaceIdentityFields(updatedData));
      handleGoNext();
    } catch (err) {
      handleError(err);
    }
  };

  const firstName = useWatch({
    control,
    name: 'first_name',
  });

  const lastName = useWatch({
    control,
    name: 'last_name',
  });

  const nationality = useWatch({
    control,
    name: 'nationality',
  });

  const isPep = useWatch({
    control,
    name: 'is_pep',
  });

  const documentType = useWatch({
    control,
    name: 'identity_document.type',
  });

  const dateOfBirth = useWatch({
    control,
    name: 'date_of_birth',
  });

  const [{ loading: getOnfidoLoading }, getOnfidoToken] = useAxios(
    {
      url: API_POST_ONFIDO_INIT,
      method: 'POST',
    },
    { manual: true }
  );

  const handleOnfidoInit = async () => {
    try {
      const { data } = await getOnfidoToken({
        data: {
          first_name: firstName,
          last_name: lastName,
          date_of_birth: dateOfBirth,
          nationality,
        },
      });
      setOnfidoToken(data.token);
      setOnfidoDisabled(false);
    } catch (err) {
      setOnfidoDisabled(true);
    }
  };

  const renderOnfidoButton = () => {
    if (onfidoCompleted || isSubmitted) {
      return (
        <Notification color='success'>
          {t('global.btn_onfido-init--success')}
        </Notification>
      );
    }

    const agreementTranslation = () => (
      <Trans i18nKey='identity.onfido.consent'>
        I have read, understand and accept
        <a
          href='https://onfido.com/facial-scan-policy-and-release/'
          target='_blank'
          rel='noreferrer'>
          Onfido Facial Scan Policy and Release
        </a>
        ,
        <a href='https://onfido.com/privacy/' target='_blank' rel='noreferrer'>
          Onfido Privacy Policy
        </a>
        and
        <a
          href='https://onfido.com/terms-of-service/'
          target='_blank'
          rel='noreferrer'>
          Onfido Terms of Service
        </a>
        .
      </Trans>
    );

    return (
      <>
        <p>{agreementTranslation()}</p>
        <Button
          color='primary'
          onClick={handleOnfidoInit}
          mt={3}
          disabled={
            isDisabled ||
            getOnfidoLoading ||
            !(firstName && lastName && nationality && isPep !== undefined)
          }>
          {getOnfidoLoading ? t('global.loading') : t('global.btn_onfido-init')}
        </Button>
        <div id={onfidoContainerId} />
      </>
    );
  };

  const pepTranslation = () => (
    <Trans i18nKey='global.form.is_pep'>
      Iam a
      <a
        href='https://intercom.help/bity-help-center/en/articles/1505730-what-is-a-pep'
        target='_blank'
        rel='noreferrer'>
        politically exposed person
      </a>
      or an immediate family member of, or closely related to, such a person.
    </Trans>
  );

  useEffect(() => {
    if (onfidoToken && onfidoContainerId && nationality) {
      const { config } = useOnfidoConfigSelection(countryList, nationality);

      const onfido = Onfido.init({
        useModal: true,
        isModalOpen: true,
        shouldCloseOnOverlayClick: false,
        // @ts-ignore
        language: i18next.language,
        onModalRequestClose() {
          // Update options with the state of the modal
          onfido.setOptions({ isModalOpen: false });
          onfido.tearDown();
        },
        token: onfidoToken,
        containerId: onfidoContainerId,
        steps: [
          'welcome',
          {
            type: 'document',
            options: {
              documentTypes: config,
            },
          },
          {
            type: 'face',
            options: {
              requestedVariant: 'video',
            },
          },
          'complete',
        ],
        onComplete: () => {
          setOnfidoCompleted(true);
          onfido.setOptions({ isModalOpen: false });
          onfido.tearDown();
          handleSubmit(handleSubmitIdentity);
        },
      });
    }
  }, [onfidoToken]);

  useEffect(() => {
    setOnfidoDisabled(false);
  }, [nationality]);

  return (
    <Container>
      <Heading>{t('identity.title')}</Heading>
      <Box>
        <Columns>
          <Column>
            <Controller
              control={control}
              name='first_name'
              render={({ field: { onChange, value } }) => (
                <InputText
                  label='identity.fields.first_name'
                  onChange={onChange}
                  value={value}
                  error={errors.first_name}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
          <Column>
            <Controller
              control={control}
              name='last_name'
              render={({ field: { onChange, value } }) => (
                <InputText
                  label='identity.fields.last_name'
                  onChange={onChange}
                  value={value}
                  error={errors.last_name}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Controller
              control={control}
              name='date_of_birth'
              render={({ field: { onChange, value } }) => (
                <InputText
                  label='identity.fields.date_of_birth'
                  type='date'
                  onChange={onChange}
                  value={value}
                  placeholder='identity.placeholders.date_of_birth'
                  error={errors.date_of_birth}
                  isDisabled={isDisabled}
                />
              )}
            />
          </Column>
          <Column>
            <Controller
              control={control}
              name='nationality'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label='identity.fields.nationality'
                  onChange={onChange}
                  value={value}
                  error={errors.nationality}
                  isDisabled={isDisabled}
                  optionsType={OPTIONS_TYPES.COUNTRIES}
                />
              )}
            />
          </Column>
        </Columns>
        <Form.Label>
          <span style={{ color: 'red' }}>*&nbsp;</span>
          {pepTranslation()}
        </Form.Label>
        <RadioButton
          control={control}
          options={isPepSelfData()}
          name='is_pep'
          isDisabled={isDisabled}
          error={errors.is_pep}
          isBool
          defaultChecked={defaultFields.is_pep}
        />
        {isPep && (
          <Controller
            control={control}
            name='pep_explanation'
            render={({ field: { onChange, value } }) => (
              <TextArea
                label='global.form.pep_description'
                onChange={onChange}
                value={value}
                error={errors.pep_explanation}
                isDisabled={isDisabled}
                required
              />
            )}
          />
        )}
        {onfidoDisabled ? (
          <>
            <Block>
              <Notification color='warning'>
                {t('global.onfido_fail-message')}
              </Notification>
            </Block>
            <Controller
              control={control}
              name='identity_document.type'
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label={t('global.file_document_upload')}
                  onChange={onChange}
                  value={value}
                  error={errors.identity_document?.type}
                  isDisabled={isDisabled}
                  selectOptions={DOCUMENT_IDENTITY_TYPES(nationality)}
                />
              )}
            />
            <Controller
              control={control}
              name='identity_document.files'
              render={({ field: { onChange } }) => (
                <UploadFile
                  isDisabled={isDisabled || !documentType}
                  error={errors.identity_document?.files}
                  onChange={onChange}
                  multiple
                />
              )}
            />
          </>
        ) : (
          renderOnfidoButton()
        )}
      </Box>

      <NavigationButtons
        onClickNext={
          isSubmitted ? handleGoNext : handleSubmit(handleSubmitIdentity)
        }
        isLoading={!!getLoading}
        isNextDisabled={!onfidoDisabled && !onfidoCompleted && !isSubmitted}
      />
    </Container>
  );
}

export default withErrorBoundary(Identity, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
