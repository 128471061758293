import { useState } from 'react';
import { Box, Container, Heading, Modal, Button } from 'react-bulma-components';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  replaceInquiryOOFExplanationState,
  getInquiryOOFExplanation,
} from 'Components/Inquiry/InquiryOOFExplanation/InquiryOOFExplanationSlice';
import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';

import { API_POST_OOF_EXPLANATION } from 'Api/constants';
import { ROUTE_STEP_INQUIRY } from 'Router/constants';

import InquiryOOFEplanationForm from 'Components/Inquiry/InquiryOOFExplanation/InquiryOOFExplanationForm';
import TextArea from 'GlobalComponents/Form/TextArea/TextArea';
import { formInquiryOOFExplanationSchema } from 'GlobalComponents/Form/ValidationSchemas';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

const shortid = require('shortid');

const InquiryOriginOfFundsExplanation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const defaultFields = useSelector(getInquiryOOFExplanation);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formInquiryOOFExplanationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'origin_of_funds_documents',
  });

  const [{ loading: getLoading }, postInquiryOOFExplanationData] = useAxios(
    {
      url: API_POST_OOF_EXPLANATION,
      method: 'POST',
    },
    { manual: true }
  );

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddForm = () => {
    setIsModalOpen(true);
  };

  const handleRemoveForm = (index: number) => {
    remove(index);
  };

  const handleSubmitOOFExplanation = async (data: any) => {
    try {
      await postInquiryOOFExplanationData({ data });
      dispatch(replaceInquiryOOFExplanationState(data));
      navigate(`/${ROUTE_STEP_INQUIRY}`);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Container>
      <Heading>{t('inquiry.steps.OOFExplanation.title')}</Heading>
      <Box>
        <Controller
          control={control}
          name='explanation'
          render={({ field: { onChange, value } }) => (
            <TextArea
              label='inquiry.steps.OOFExplanation.fields.explanation'
              placeholder='origin_of_funds.placeholders.explanation'
              onChange={onChange}
              value={value}
              maxLength={450}
              error={errors.explanation}
              required
            />
          )}
        />
      </Box>

      {!!fields.length && (
        <Heading subtitle>{t('inquiry.steps.OOFExplanation.subtitle')}</Heading>
      )}
      {fields.map((field, index) => (
        <Box key={shortid.generate()}>
          <InquiryOOFEplanationForm
            {...{ control, index: index + 1, append, field }}
            isDisabled
          />
          <Button onClick={() => handleRemoveForm(index)}>Remove</Button>
        </Box>
      ))}
      {fields.length < 5 && (
        <Button onClick={() => handleAddForm()}>
          {t('inquiry.steps.OOFExplanation.btn_add_document')}
        </Button>
      )}
      <NavigationButtons
        onClickNext={handleSubmit(handleSubmitOOFExplanation)}
        isLoading={!!getLoading}
        isNextDisabled={!!getLoading}
        isNextDisplayed={!!fields.length}
        nextLabel='global.form.submit'
      />
      <Modal show={isModalOpen} closeOnEsc onClose={handleCloseModal}>
        <Modal.Card>
          <Modal.Card.Body>
            <InquiryOOFEplanationForm
              {...{
                control,
                index: 1,
                append,
                field: defaultFields.origin_of_funds_documents,
              }}
              onModalClose={handleCloseModal}
            />
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </Container>
  );
};
export default withErrorBoundary(InquiryOriginOfFundsExplanation, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
