// QUINTEN API
export const API_INDIVIDUAL = 'individual';

export const API_GET_STATUS = `/${API_INDIVIDUAL}/status`;

export const API_POST_ONFIDO_INIT = `/${API_INDIVIDUAL}/onfido/init`;

export const API_POST_IDENTITY = `/${API_INDIVIDUAL}/identity`;
export const API_POST_RESIDENCE = `/${API_INDIVIDUAL}/residence`;
export const API_POST_INCOME = `/${API_INDIVIDUAL}/income`;
export const API_POST_OOF = `/${API_INDIVIDUAL}/origin_of_funds_declaration`;
export const API_POST_UBO = `/${API_INDIVIDUAL}/ubo`;
export const API_POST_INQUIRY = `/${API_INDIVIDUAL}/inquiry`;
export const API_POST_GENERIC_DOCUMENT = `/${API_INDIVIDUAL}/generic_document`;
export const API_POST_OOF_EXPLANATION = `/${API_INDIVIDUAL}/origin_of_funds_explanation`;

export const API_POST_INQUIRY_BENEFICIARY = `/${API_INDIVIDUAL}/order_beneficiary_declaration`;

export const API_POST_PHONE_INITIATE_CHANGE = `/${API_INDIVIDUAL}/phone/initiate_change`;
export const API_POST_SMS_CODE = `/${API_INDIVIDUAL}/phone/confirm`;

export const API_POST_UBO_SMS_CODE = `/sms_code`;

// QUINTEN STATUS
export const STATUS_TASK_PHONE = 'phone';
export const STATUS_TASK_IDENTITY = 'identity';
export const STATUS_TASK_RESIDENCE = 'residence';
export const STATUS_TASK_INCOME = 'income';
export const STATUS_TASK_OFD = 'origin_of_funds_declaration';
export const STATUS_TASK_OFE = 'origin_of_funds_explanation';
export const STATUS_TASK_UBO = 'ubo';

// QUINTEN STATUS FOR NO TASK
export const STATUS_NO_CURRENT_TASK = 'none';

// QUINTEN INQUIRY STATUS
export const STATUS_INQUIRY_WAITING = 'waiting';
export const STATUS_INQUIRY_REQUEST = 'inquiry';

// DATA API
export const DATA_API_COUNTRIES = 'countries/compendium';
export const DATA_API_COUNTRIES_ALPHA2 = 'iso_3166_alpha2';
export const DATA_API_COUNTRIES_ALPHA3 = 'iso_3166_alpha3';
