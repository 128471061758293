import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Block, Notification, Heading, Columns } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import type { PhoneVerificationTypes } from 'Components/PhoneVerification/PhoneVerificationTypes';

import { getPhoneNumber } from 'Components/PhoneVerification/PhoneVerificationSlice';

import { ROUTE_STEP_IDENTITY } from 'Router/constants';
import { API_POST_SMS_CODE, STATUS_TASK_PHONE } from 'Api/constants';
import { formPhoneSmsCodeSchema } from 'GlobalComponents/Form/ValidationSchemas';

import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import InputText from 'GlobalComponents/Form/InputText/InputText';

import { useStatusChecker } from 'helpers';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

const { Column } = Columns;

function PhoneSmsCode({ onModalClose }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const phoneInStore = useSelector(getPhoneNumber);

  const [smsCodeError, setSmsCodeError] = useState<string>('');

  const defaultPhoneFields = { phone: '', code: '+41' };

  const isSubmitted = useStatusChecker(STATUS_TASK_PHONE);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<PhoneVerificationTypes>({
    defaultValues: defaultPhoneFields,
    mode: 'onChange',
    resolver: yupResolver(formPhoneSmsCodeSchema),
  });

  const [{ loading: getCodeLoading }, submitPhoneCode] = useAxios(
    {
      url: API_POST_SMS_CODE,
      method: 'POST',
    },
    { manual: true }
  );

  const handleSubmitPhoneVerification = () => {
    navigate(`/${ROUTE_STEP_IDENTITY}`);
  };

  const handleSubmitPhoneCode = async (data: any) => {
    try {
      await submitPhoneCode({
        data: { code: data.code, phone: phoneInStore.phone },
      });
      onModalClose();
      handleSubmitPhoneVerification();
    } catch (err: any) {
      if (err.response.data.code) {
        setSmsCodeError(err.response.data.code);
      } else {
        handleError(err);
      }
    }
  };

  const renderCodeInput = () => (
    <Column>
      <Controller
        control={control}
        name='code'
        render={({ field: { onChange, value } }) => (
          <InputText
            label='phone_verification.fields.code'
            onChange={onChange}
            value={value}
            placeholder='phone_verification.placeholders.code'
            type='number'
            error={smsCodeError ? t(`errors.${smsCodeError}`) : errors.code}
            isDisabled={!!getCodeLoading}
          />
        )}
      />
    </Column>
  );

  useEffect(() => {
    if (phoneInStore) {
      setValue('phone', phoneInStore.phone);
    }
  }, [phoneInStore]);

  return (
    <>
      <Heading>{t('phone_verification.title_sms')}</Heading>
      {isSubmitted ? (
        <Block>
          <Notification color='success'>
            {t('phone_verification.success_submitted')}
          </Notification>
        </Block>
      ) : (
        renderCodeInput()
      )}
      <NavigationButtons
        onClickNext={handleSubmit(handleSubmitPhoneCode)}
        isBackDisplayed={false}
        isNextDisabled={!!errors.code || getCodeLoading}
        nextColor='success'
        nextLabel='phone_verification.send_code'
      />
    </>
  );
}

export default withErrorBoundary(PhoneSmsCode, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
