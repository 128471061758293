import {
  ROUTE_STEP_IDENTITY,
  ROUTE_STEP_RESIDENCE,
  ROUTE_STEP_INCOME,
  ROUTE_STEP_OOF,
  ROUTE_STEP_UBO,
} from 'Router/constants';

import {
  API_POST_IDENTITY,
  API_POST_RESIDENCE,
  API_POST_INCOME,
  API_POST_OOF,
  API_POST_UBO,
} from 'Api/constants';

import { replaceIdentityFields } from 'Components/Identity/IdentitySlice';
import { replaceResidenceFields } from 'Components/Residence/ResidenceSlice';
import { replaceIncomeFields } from 'Components/Income/IncomeSlice';
import { replaceOriginOfFundsFields } from 'Components/OriginOfFunds/OriginOfFundsSlice';
import { replaceUboFields } from 'Components/Ubo/UboSlice';

const apiRouteName = (route: string) => {
  switch (route) {
    case `/${ROUTE_STEP_IDENTITY}`:
      return { apiUrl: API_POST_IDENTITY, action: replaceIdentityFields };
    case `/${ROUTE_STEP_RESIDENCE}`:
      return { apiUrl: API_POST_RESIDENCE, action: replaceResidenceFields };
    case `/${ROUTE_STEP_INCOME}`:
      return { apiUrl: API_POST_INCOME, action: replaceIncomeFields };
    case `/${ROUTE_STEP_OOF}`:
      return { apiUrl: API_POST_OOF, action: replaceOriginOfFundsFields };
    case `/${ROUTE_STEP_UBO}`:
      return { apiUrl: API_POST_UBO, action: replaceUboFields };
    default:
      return { apiUrl: undefined, action: undefined };
  }
};

export default apiRouteName;
