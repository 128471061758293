import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { pullAll, isEmpty } from 'lodash';

import {
  Box,
  Button,
  Heading,
  Level,
  Message,
  Modal,
} from 'react-bulma-components';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import InquiryBeneficiaryFormOrganization from 'Components/InquiryBeneficiary/InquiryBeneficiaryFormOrganization';
import InquiryBeneficiaryFormPersonal from 'Components/InquiryBeneficiary/InquiryBeneficiaryFormPersonal';
import type { InquiryBeneficiaryOrderTypes } from 'Components/InquiryBeneficiary/InquiryBeneficiarySlice';
import { addInquiryBeneficiaryFields } from 'Components/InquiryBeneficiary/InquiryBeneficiarySlice';
import SelectList from 'GlobalComponents/Form/SelectList/SelectList';

import { API_POST_INQUIRY_BENEFICIARY } from 'Api/constants';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

import { INQUIRY_BENEFICIARY_CHOICE } from 'globalData';
import { getOrderIdsToSupport } from 'helpers';

function InquiryBeneficiaryOrder({
  wallet,
  indexOfOrder,
  append,
  update,
  remove,
  fields,
  setOrderToSupport,
  orderToSupport,
}: any) {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const dispatch = useDispatch();
  // const defaultFields = useSelector(getInquiryBeneficiaryFields);

  const [isModalPersonalOpen, setIsModalPersonalOpen] =
    useState<boolean>(false);
  const [isModalOrganizationOpen, setIsModalOrganizationOpen] =
    useState<boolean>(false);
  const [inquiryChoice, setInquiryChoice] = useState('null');
  const [editMode, setEditMode] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [beneficiaryToDisplay, setBeneficiaryToDisplay] = useState(
    wallet[1][0].beneficiaries[0]
  );

  const { control } = useForm({
    mode: 'onSubmit',
    defaultValues: fields,
  });

  const fieldFormatted = fields.filter((el: any) => el.orderId === wallet[0]);
  const fieldToDisplay = { ...fieldFormatted[0] };
  const fieldIndex = fields.findIndex((el: any) => el.orderId === wallet[0]);

  const [{ loading: postInquiryBeneficiaryLoading }, postInquiryBeneficiary] =
    useAxios(
      {
        url: API_POST_INQUIRY_BENEFICIARY,
        method: 'POST',
      },
      { manual: true }
    );

  const handleCloseModal = (type: string) => {
    setIsModalPersonalOpen(false);
    setIsModalOrganizationOpen(false);
    setEditMode(false);
    setAccountType(type);
  };

  const handleSubmitBeneficiary = async (isRemoval = false, isSelf = false) => {
    const beneficiary = fieldToDisplay.name
      ? { name: fieldToDisplay.name, country: fieldToDisplay.country }
      : {
          first_name: fieldToDisplay.first_name,
          last_name: fieldToDisplay.last_name,
          country_of_residence: fieldToDisplay.country_of_residence,
        };

    const data = {
      output_crypto_addresses: {
        [indexOfOrder]: [
          { order_beneficiary_type: accountType, ...beneficiary },
        ],
      },
      finalized: false,
    };

    const dataRemoval = {
      output_crypto_addresses: {
        [indexOfOrder]: [],
      },
      finalized: false,
    };

    const dataIsSelf = {
      output_crypto_addresses: {
        [indexOfOrder]: [{ order_beneficiary_type: 'account_owner' }],
      },
      finalized: false,
    };

    const dataToSend = () => {
      if (isRemoval) {
        return dataRemoval;
      }
      if (isSelf) {
        return dataIsSelf;
      }
      return data;
    };

    try {
      await postInquiryBeneficiary({
        data: dataToSend(),
      });
      setBeneficiaryToDisplay(isRemoval ? '' : fieldToDisplay);
      dispatch(addInquiryBeneficiaryFields(fieldToDisplay));
    } catch (err) {
      handleError(err);
    }
  };

  // const handleEditFormPersonal = () => {
  //   setEditMode(true);
  //   setIsModalPersonalOpen(true);
  // };

  // const handleEditFormOrganization = () => {
  //   setEditMode(true);
  //   setIsModalOrganizationOpen(true);
  // };

  const btnIsDisabled =
    inquiryChoice === 'is_self' ||
    inquiryChoice === 'some' ||
    inquiryChoice === 'unknown' ||
    inquiryChoice === 'null' ||
    !!fieldFormatted.length ||
    postInquiryBeneficiaryLoading;

  const handleRemoveBeneficiary = () => {
    remove(fieldIndex);
    setInquiryChoice('null');
    handleSubmitBeneficiary(true);
  };

  const handleSelectChoice = (value: string) => {
    setInquiryChoice(value);

    if (value !== 'some') {
      const ordersForSupport = getOrderIdsToSupport(wallet[1]);
      const newList = pullAll(orderToSupport, ordersForSupport);
      setOrderToSupport(newList);
    }

    if (value === 'is_self') {
      setAccountType('account_owner');
      remove(fieldIndex);
      handleSubmitBeneficiary(false, true);
    }

    if (value === 'some') {
      const ordersForSupport = getOrderIdsToSupport(wallet[1]);
      setOrderToSupport((oldArray: any) => [...oldArray, ...ordersForSupport]);
    }
  };

  const renderBeneficiaryOrganization = () => (
    <div>
      <strong>{t('inquiry_beneficiary.beneficiary_message.name')}</strong>{' '}
      {beneficiaryToDisplay.name}
      <br />
      {beneficiaryToDisplay.country && (
        <div>
          <strong>
            {t('inquiry_beneficiary.beneficiary_message.country')}
          </strong>{' '}
          {beneficiaryToDisplay.country}
        </div>
      )}
    </div>
  );

  const renderBeneficiaryPerson = () => (
    <div>
      <strong>{t('inquiry_beneficiary.beneficiary_message.first_name')}</strong>{' '}
      {beneficiaryToDisplay.first_name}
      <br />{' '}
      <strong>
        {t('inquiry_beneficiary.beneficiary_message.last_name')}
      </strong>{' '}
      {beneficiaryToDisplay.last_name}
      <br />
      {beneficiaryToDisplay.country_of_residence && (
        <div>
          <strong>
            {t('inquiry_beneficiary.beneficiary_message.residence_country')}
          </strong>
          {beneficiaryToDisplay.country_of_residence}
        </div>
      )}
    </div>
  );

  const isDisabled = !!beneficiaryToDisplay;
  const renderBeneficiaryMessage = () => {
    if (inquiryChoice === 'some') {
      return t('inquiry_beneficiary.beneficiary_message.choice.some');
    }
    if (inquiryChoice === 'is_self') {
      return t('inquiry_beneficiary.beneficiary_message.choice.is_self');
    }
    if (inquiryChoice === 'unknown') {
      return t('inquiry_beneficiary.beneficiary_message.choice.unknown');
    }
    if (beneficiaryToDisplay.name) {
      return renderBeneficiaryOrganization();
    }
    return renderBeneficiaryPerson();
  };

  useEffect(() => {
    if (
      (accountType === 'person' || accountType === 'organization') &&
      !isEmpty(fieldToDisplay)
    ) {
      handleSubmitBeneficiary();
    }
  }, [accountType]);

  useEffect(() => {
    if (
      wallet[1][0].beneficiaries[0]?.order_beneficiary_type === 'person' ||
      wallet[1][0].beneficiaries[0]?.order_beneficiary_type === 'organization'
    ) {
      setInquiryChoice('other');
    } else if (
      wallet[1][0].beneficiaries[0]?.order_beneficiary_type === 'account_owner'
    ) {
      setInquiryChoice('is_self');
    }
  }, []);

  return (
    <>
      <Box>
        <Heading subtitle>
          {t('inquiry_beneficiary.order.address')}
          <small>
            <strong>{wallet[0]}</strong>
          </small>
        </Heading>
        <Level>
          <div className='InquiryBeneficiary__select'>
            <SelectList
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSelectChoice(e.target.value)
              }
              value={inquiryChoice}
              selectOptions={INQUIRY_BENEFICIARY_CHOICE}
              isDisabled={isDisabled}
            />
          </div>
          <Level.Side align='right'>
            <Level.Item>
              <Button
                color='primary'
                onClick={() => setIsModalPersonalOpen(true)}
                disabled={btnIsDisabled || isDisabled}>
                {t('inquiry_beneficiary.btn_personal')}&nbsp;
                <sup>(3)</sup>
              </Button>
            </Level.Item>
            <Level.Item>
              <Button
                color='primary'
                onClick={() => setIsModalOrganizationOpen(true)}
                disabled={btnIsDisabled || isDisabled}>
                {t('inquiry_beneficiary.btn_organization')}&nbsp;
                <sup>(4)</sup>
              </Button>
            </Level.Item>
          </Level.Side>
        </Level>
        <Box>
          {wallet[1].map(
            ({
              executed_at,
              created_at,
              external_order_id,
              input_amount,
            }: InquiryBeneficiaryOrderTypes) => {
              const orderDate = new Date(
                executed_at || created_at
              ).toDateString();

              return (
                <Level key={external_order_id}>
                  <Level.Side>
                    {t('inquiry_beneficiary.order.order_id')}&nbsp;
                    <strong>{external_order_id}</strong>
                  </Level.Side>
                  <Level.Side>
                    {t('inquiry_beneficiary.order.order_date')}&nbsp;
                    <strong>{orderDate}</strong>
                  </Level.Side>
                  <Level.Side align='right'>
                    {t('inquiry_beneficiary.order.order_amount')}&nbsp;
                    <strong>
                      {input_amount.value} {input_amount.currency}
                    </strong>
                  </Level.Side>
                </Level>
              );
            }
          )}
        </Box>
        {(!!beneficiaryToDisplay ||
          inquiryChoice === 'some' ||
          inquiryChoice === 'unknown') && (
          <Message color='success'>
            <Message.Header>
              <span>{t('inquiry_beneficiary.beneficiary_message.title')}</span>
              <div>
                {/* <Button
                  color='primary'
                  onClick={() =>
                    beneficiaryToDisplay.name
                      ? handleEditFormOrganization()
                      : handleEditFormPersonal()
                  }>
                  {t('Edit')}
                </Button> */}
                <Button
                  color='danger'
                  onClick={() => handleRemoveBeneficiary()}>
                  {t('inquiry_beneficiary.beneficiary_message.btn_remove')}
                </Button>
              </div>
            </Message.Header>
            <Message.Body>{renderBeneficiaryMessage()}</Message.Body>
          </Message>
        )}
      </Box>
      <Modal
        show={isModalPersonalOpen}
        closeOnEsc
        onClose={() => handleCloseModal('person')}>
        <Modal.Card className='InquiryBeneficiary__modal-card'>
          <Modal.Card.Body>
            <InquiryBeneficiaryFormPersonal
              {...{
                control,
                append,
                update,
                indexArray: fieldIndex,
                index: indexOfOrder,
                field: fieldToDisplay,
                editMode,
              }}
              // isDisabled={isDisabled}
              onModalClose={handleCloseModal}
            />
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <Modal
        show={isModalOrganizationOpen}
        closeOnEsc
        onClose={() => handleCloseModal('organization')}>
        <Modal.Card className='InquiryBeneficiary__modal-card'>
          <Modal.Card.Body>
            <InquiryBeneficiaryFormOrganization
              {...{
                control,
                append,
                update,
                editMode,
                indexArray: fieldIndex,
                index: indexOfOrder,
                field: fieldToDisplay,
              }}
              // isDisabled={isDisabled}
              onModalClose={handleCloseModal}
            />
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </>
  );
}

export default withErrorBoundary(InquiryBeneficiaryOrder, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
