export const API_BASE_URL = 'https://quinten.bity.com/api';

export const BITY_API_EXCHANGE_URL = 'https://exchange.api.bity.com';
export const BITY_API_AUTHORIZATION_URL =
  'https://connect.bity.com/oauth2/auth';
export const BITY_API_TOKEN_URL = 'https://connect.bity.com/oauth2/token';
export const BITY_API_REDIRECT_URL = 'https://kyc.my.bity.com';
export const BITY_API_CLIENT_ID = 'd44be01a-f9c5-43af-9142-e9b12df996c4';

export const BITY_API_SCOPE_URL = 'https://auth.bity.com/scopes';

export const BITY_API_SCOPE_KYC = 'quinten.api.submit_kyc';

export const BITY_KYC_URL_LEGAL_ENTITY = 'https://my.bity.com/dashboard/kyc';

export const BITY_DATA_API = 'https://data.bity.com';
