export const incomeAnnualSelect = [
  { name: 'global.form.list.default.range', key: '' },
  { name: 'income.data.list_annual.1', key: '10000' },
  { name: 'income.data.list_annual.2', key: '50000' },
  { name: 'income.data.list_annual.3', key: '100000' },
  { name: 'income.data.list_annual.4', key: '200000' },
  { name: 'income.data.list_annual.5', key: '500000' },
  { name: 'income.data.list_annual.6', key: '1000000' },
  { name: 'income.data.list_annual.7', key: '5000000' },
  { name: 'income.data.list_annual.8', key: 'Infinity' },
];

export const incomeExpectedTradingVolumeSelect = [
  { name: 'global.form.list.default.range', key: '' },
  { name: 'income.data.list_trading.1', key: '50000' },
  { name: 'income.data.list_trading.2', key: '100000' },
  { name: 'income.data.list_trading.3', key: '500000' },
  { name: 'income.data.list_trading.4', key: '1000000' },
  { name: 'income.data.list_trading.5', key: 'Infinity' },
];

export const incomeIndustrySelect = [
  { name: 'global.form.list.default.industry', key: '' },
  { name: 'income.industry_select.food_service', key: 'food_service' },
  { name: 'income.industry_select.extraterritorial', key: 'extraterritorial' },
  {
    name: 'income.industry_select.domestic_personnel',
    key: 'domestic_personnel',
  },
  {
    name: 'income.industry_select.administrative_support',
    key: 'administrative_support',
  },
  { name: 'income.industry_select.agriculture', key: 'agriculture' },
  { name: 'income.industry_select.arts', key: 'arts' },
  { name: 'income.industry_select.construction', key: 'construction' },
  { name: 'income.industry_select.education', key: 'education' },
  { name: 'income.industry_select.electricity', key: 'electricity' },
  {
    name: 'income.industry_select.financial_insurance',
    key: 'financial_insurance',
  },
  {
    name: 'income.industry_select.human_health_social',
    key: 'human_health_social',
  },
  {
    name: 'income.industry_select.information_communication',
    key: 'information_communication',
  },
  { name: 'income.industry_select.manufacturing', key: 'manufacturing' },
  { name: 'income.industry_select.mining_quarrying', key: 'mining_quarrying' },
  {
    name: 'income.industry_select.private_households',
    key: 'private_households',
  },
  {
    name: 'income.industry_select.professional_scientific_technical',
    key: 'professional_scientific_technical',
  },
  {
    name: 'income.industry_select.public_administration',
    key: 'public_administration',
  },
  { name: 'income.industry_select.real_estate', key: 'real_estate' },
  { name: 'income.industry_select.water_supply', key: 'water_supply' },
  {
    name: 'income.industry_select.transportation_storage',
    key: 'transportation_storage',
  },
  {
    name: 'income.industry_select.retail_trade_repare_motors',
    key: 'retail_trade_repare_motors',
  },
  {
    name: 'income.industry_select.others',
    key: 'others',
  },
];
