import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Block,
  Notification,
  Heading,
  Columns,
  Modal,
} from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';

import type { PhoneVerificationTypes } from 'Components/PhoneVerification/PhoneVerificationTypes';

import {
  replacePhoneNumber,
  getPhoneNumber,
} from 'Components/PhoneVerification/PhoneVerificationSlice';

import { ROUTE_STEP_IDENTITY } from 'Router/constants';
import {
  API_POST_PHONE_INITIATE_CHANGE,
  STATUS_TASK_PHONE,
} from 'Api/constants';
import { formPhoneVerificationSchema } from 'GlobalComponents/Form/ValidationSchemas';

import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import InputText from 'GlobalComponents/Form/InputText/InputText';
import SelectList, {
  OPTIONS_TYPES,
} from 'GlobalComponents/Form/SelectList/SelectList';
import PhoneSmsCode from 'Components/PhoneSmsCode/PhoneSmsCode';
import { useStatusChecker } from 'helpers';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

import './PhoneVerification.scss';

const { Column } = Columns;

function PhoneVerification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const dispatch = useDispatch();
  const phoneInStore = useSelector(getPhoneNumber);

  const [callingCode, setCallingCode] = useState<string>(
    phoneInStore.callingCode || ''
  );
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const defaultPhoneFields = { phone: '', code: phoneInStore.callingCode };

  const isSubmitted = useStatusChecker(STATUS_TASK_PHONE);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<PhoneVerificationTypes>({
    defaultValues: defaultPhoneFields,
    mode: 'onChange',
    resolver: yupResolver(formPhoneVerificationSchema),
  });

  const isSendingPhoneReady = useWatch({ control, name: 'phone' });

  const [{ loading: getInitiateLoading }, submitPhoneRequest] = useAxios(
    {
      url: API_POST_PHONE_INITIATE_CHANGE,
      method: 'POST',
    },
    { manual: true }
  );

  const handleSubmitPhoneRequest = async (data: any) => {
    const phoneNumberFormatted = callingCode + data.phone;

    try {
      await submitPhoneRequest({ data: { phone: phoneNumberFormatted } });
      setIsModalOpen(true);
    } catch (err: any) {
      if (err.response.data.code) {
        setPhoneNumberError(err.response.data.code);
      } else {
        handleError(err);
      }
    } finally {
      dispatch(
        replacePhoneNumber({
          phone: phoneNumberFormatted,
          rawPhone: data.phone,
          callingCode,
        })
      );
    }
  };

  const handleCallingCodeChange = (e: any) => {
    setCallingCode(e.target.value);
  };

  const renderPhoneInput = () => (
    <>
      <Columns>
        <Column size={4}>
          <SelectList
            onChange={handleCallingCodeChange}
            value={callingCode}
            optionsType={OPTIONS_TYPES.CALLING_CODES}
          />
        </Column>
        <Column size={4}>
          <Controller
            control={control}
            name='phone'
            render={({ field: { onChange, value } }) => (
              <InputText
                onChange={onChange}
                value={value || phoneInStore.rawPhone}
                placeholder={t('phone_verification.fields.phone')}
                type='number'
                error={
                  phoneNumberError
                    ? t(`errors.${phoneNumberError}`)
                    : errors.phone
                }
                isDisabled={!!getInitiateLoading}
              />
            )}
          />
        </Column>
      </Columns>
    </>
  );

  const renderPhoneVerification = () => (
    <>
      {isSubmitted ? (
        <Block>
          <Notification color='success'>
            {t('phone_verification.success_submitted')}
          </Notification>
        </Block>
      ) : (
        renderPhoneInput()
      )}
    </>
  );

  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (phoneInStore) {
      setValue('phone', phoneInStore.rawPhone);
    }
  }, [phoneInStore]);

  return (
    <Container>
      <Heading>{t('phone_verification.title')}</Heading>
      <Box>{renderPhoneVerification()}</Box>
      <Modal show={isModalOpen} closeOnEsc onClose={handleCloseModal}>
        <Modal.Card className='PhoneVerification__modal-card'>
          <Modal.Card.Body>
            <PhoneSmsCode onModalClose={handleCloseModal} />
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <NavigationButtons
        onClickNext={
          isSubmitted
            ? () => navigate(`/${ROUTE_STEP_IDENTITY}`)
            : handleSubmit(handleSubmitPhoneRequest)
        }
        isNextDisabled={
          isSubmitted
            ? false
            : getInitiateLoading || !isSendingPhoneReady || !!errors.phone
        }
        nextLabel={
          isSubmitted
            ? t('navigation.next')
            : t('phone_verification.send_phone')
        }
        isSecondaryButton={!isSubmitted && !!phoneInStore.phone}
        secondaryLabel='phone_verification.i_have_a_code'
        onClickSecondary={() => setIsModalOpen(true)}
      />
    </Container>
  );
}

export default withErrorBoundary(PhoneVerification, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
