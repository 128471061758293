import {
  Container,
  Hero,
  Heading,
  Block,
  Button,
} from 'react-bulma-components';
import { useTranslation } from 'react-i18next';

interface LoginPagePropsTypes {
  handleLogin: () => void;
}

const LoginPage = ({ handleLogin }: LoginPagePropsTypes) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Hero>
        <Hero.Body textAlign='center'>
          <Block>
            <Heading>{t('login_page.heading')}</Heading>
            <Heading subtitle>{t('login_page.subheading')}</Heading>
          </Block>
          <Block m='6'>
            <Button color='primary' onClick={() => handleLogin()}>
              {t('navigation.login')}
            </Button>
          </Block>
        </Hero.Body>
      </Hero>
    </Container>
  );
};

export default LoginPage;
