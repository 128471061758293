import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bulma-components';

const { Field, Control, Label, Help, Textarea } = Form;

interface TextAreaPropsTypes {
  onChange: any;
  value: any;
  label?: string;
  error?: any;
  isDisabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  optionalLabel?: string;
}

const TextArea = React.forwardRef<any, TextAreaPropsTypes>(
  (
    {
      onChange,
      value,
      label,
      error,
      isDisabled,
      placeholder,
      maxLength,
      required,
      optionalLabel,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Field>
        <Label>
          {required && <span style={{ color: 'red' }}>*&nbsp;</span>}
          {t(`${label}`)}
          {!required && (
            <span style={{ float: 'right', fontWeight: 'normal' }}>
              {t(`${optionalLabel}`)}
            </span>
          )}
        </Label>
        <Control>
          <Textarea
            onChange={onChange}
            value={value}
            placeholder={placeholder && t(`${placeholder}`)}
            color={error ? 'danger' : ''}
            disabled={isDisabled}
            maxLength={maxLength}
          />
        </Control>
        {error && (
          <Help className='has-text-danger'>
            {error.message ? error.message : error}
          </Help>
        )}
      </Field>
    );
  }
);

export default TextArea;
