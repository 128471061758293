import { useState } from 'react';
import { Heading, Button, Modal } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks';

import UploadFile from 'GlobalComponents/Form/UploadFile/UploadFile';
import { formInquiryGenericDocumentsSchema } from 'GlobalComponents/Form/ValidationSchemas';
import { ROUTE_STEP_INQUIRY } from 'Router/constants';
import { API_POST_GENERIC_DOCUMENT } from 'Api/constants';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

const InquiryDocuments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { files: [] },
    mode: 'onChange',
    resolver: yupResolver(formInquiryGenericDocumentsSchema),
  });

  const [{ loading: getDocumentLoading }, postGenericDocument] = useAxios(
    {
      url: API_POST_GENERIC_DOCUMENT,
      method: 'POST',
    },
    { manual: true }
  );

  const handleSubmitGenericDocuments = async (data: any) => {
    try {
      await postGenericDocument({ data });
      navigate(`/${ROUTE_STEP_INQUIRY}`);
    } catch (err) {
      handleError(err);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(`/${ROUTE_STEP_INQUIRY}`);
  };

  return (
    <Modal
      show={isModalOpen}
      onClose={handleCloseModal}
      closeOnEsc={false}
      showClose={false}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title renderAs='div'>
            <Heading>{t('inquiry.steps.generic_documents.heading')}</Heading>
            <Heading subtitle>
              {t('inquiry.steps.generic_documents.subheading')}
            </Heading>
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Controller
            control={control}
            name='files'
            render={({ field: { onChange } }) => (
              <UploadFile
                isDisabled={getDocumentLoading}
                error={errors.files}
                onChange={onChange}
                multiple
              />
            )}
          />
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <Button
            onClick={handleSubmit(handleSubmitGenericDocuments)}
            color='primary'
            disabled={getDocumentLoading}>
            {t('global.form.submit')}
          </Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  );
};

export default withErrorBoundary(InquiryDocuments, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
