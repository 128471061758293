import { Fragment, useState, useEffect } from 'react';
import { Form, Button } from 'react-bulma-components';
import { useController } from 'react-hook-form';

const { Field, Radio, Label, Help } = Form;

interface RadioButtonPropsTypes {
  options: any;
  control: any;
  name: any;
  label?: string;
  error?: any;
  isDisabled?: boolean;
  isBool?: boolean;
  defaultChecked?: string | boolean;
}

const RadioButton = ({
  options,
  control,
  name,
  label,
  isDisabled,
  isBool,
  error,
  defaultChecked,
}: RadioButtonPropsTypes) => {
  const { field } = useController({
    control,
    name,
  });

  const [selected, setSelected] = useState<string | boolean>(
    defaultChecked || ''
  );

  const updateValue = (value: any) => {
    const valueAsBool = value === 'true';
    const formattedValue = isBool ? valueAsBool : value;

    field.onChange(formattedValue);
    setSelected(value);
  };

  useEffect(() => {
    updateValue(defaultChecked?.toString());
  }, [defaultChecked]);

  return (
    <Field>
      {label && (
        <Label>
          <span style={{ color: 'red' }}>*&nbsp;</span>
          {label}
        </Label>
      )}
      {options.map((option: any) => (
        <Fragment key={option.key}>
          <Button
            renderAs='label'
            mr={3}
            color={option.key === selected ? 'primary' : ''}>
            <Radio
              className='is-sr-only'
              name={option.key}
              onChange={({ target }) => updateValue(target.value)}
              type='checkbox'
              value={option.key}
              checked={option.key === selected}
              disabled={isDisabled}
            />
            {option.name}
          </Button>
        </Fragment>
      ))}
      {error && (
        <Help className='has-text-danger'>
          {error.message ? error.message : error}
        </Help>
      )}
    </Field>
  );
};

export default RadioButton;
