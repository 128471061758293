import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';
import type { IdentityStateTypes } from 'Components/Identity/IdentitySlice';

export interface UboEntry extends IdentityStateTypes {
  address: string;
  country: string;
  us_state?: string;
}

export interface UboEntriesTypes {
  entries: UboEntry[];
}

export interface UboStateTypes {
  entries: UboEntry[];
  include_self_as_ubo_entry: boolean;
  sms_code: string;
  agreement?: boolean;
}

export const initialEntry = {
  date_of_birth: '',
  first_name: '',
  identity_document: {
    files: [],
    type: '',
  },
  last_name: '',
  nationality: '',
  address: '',
  country: '',
};

const initialState: UboStateTypes = {
  entries: [initialEntry],
  include_self_as_ubo_entry: false,
  sms_code: '',
};

export const uboSlice = createSlice({
  name: 'ubo',
  initialState,
  reducers: {
    replaceUboFields: (state, action: PayloadAction<UboStateTypes>) =>
      action.payload,
  },
});

export const { replaceUboFields } = uboSlice.actions;

export const getUboFields = (state: RootState) => state.ubo;
export const getUboEntries = (state: RootState) => state.ubo.entries;
export const getUboEntry = (state: RootState) => state.ubo.entries[0];

export default uboSlice.reducer;
