import useAxios from 'axios-hooks';
import { useState } from 'react';
import {
  Block,
  Box,
  Button,
  Container,
  Form,
  Heading,
  Modal,
} from 'react-bulma-components';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  API_POST_UBO,
  API_POST_UBO_SMS_CODE,
  STATUS_INQUIRY_REQUEST,
  STATUS_TASK_UBO,
} from 'Api/constants';
import UboForm from 'Components/Ubo/UboForm';
import type { UboStateTypes } from 'Components/Ubo/UboSlice';
import { getUboFields, replaceUboFields } from 'Components/Ubo/UboSlice';
import Checkboxes from 'GlobalComponents/Form/Checkboxes/Checkboxes';
import InputText from 'GlobalComponents/Form/InputText/InputText';
import RadioButton from 'GlobalComponents/Form/RadioButton/RadioButton';
import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import { ROUTE_STEP_INQUIRY, ROUTE_STEP_SUCCESS } from 'Router/constants';
import { getGlobalStatusCurrentTask } from 'Store/GlobalStatusSlice';
import { useStatusChecker, isPepSelfData } from 'helpers';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

import './Ubo.scss';

const { Control, Help } = Form;

const shortid = require('shortid');

function Ubo() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const defaultFields = useSelector(getUboFields);
  const globalStatus = useSelector(getGlobalStatusCurrentTask);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenSms, setIsModalOpenSms] = useState<boolean>(false);
  const [smsErrorMsg, setSmsErrorMsg] = useState<any>();

  const isInquiry = globalStatus === STATUS_INQUIRY_REQUEST;

  const isSubmitted = useStatusChecker(STATUS_TASK_UBO);

  const { handleSubmit, control } = useForm<UboStateTypes>({
    mode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'entries',
  });

  const [{ loading: getLoading }, postUboData] = useAxios(
    {
      url: API_POST_UBO,
      method: 'POST',
    },
    { manual: true }
  );

  const [{ loading: getCodeLoading }, submitPhoneCode] = useAxios(
    {
      url: API_POST_UBO_SMS_CODE,
      method: 'POST',
    },
    { manual: true }
  );

  const isDisabled = !!getLoading || isSubmitted;

  const handleSubmitPhoneCode = async () => {
    try {
      await submitPhoneCode();
    } catch (err) {
      setSmsErrorMsg(err);
    }
  };

  // Modal SMS code
  const handleOpenModalSmsCode = () => {
    setIsModalOpenSms(true);
    handleSubmitPhoneCode();
  };

  // Submitting step and go next
  const handleSubmitUbo = async (data: any) => {
    const { agreement, ...rest } = data;

    try {
      const updatedData = {
        ...rest,
        include_self_as_ubo_entry: rest.include_self_as_ubo_entry || false,
      };
      await postUboData({
        data: updatedData,
      });
      dispatch(replaceUboFields(updatedData));
      navigate(isInquiry ? `/${ROUTE_STEP_INQUIRY}` : `/${ROUTE_STEP_SUCCESS}`);
    } catch (err) {
      handleError(err);
    }
  };

  const handleAddForm = () => {
    setIsModalOpen(true);
  };

  const handleRemoveForm = (index: number) => {
    remove(index);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isSelf = useWatch({
    control,
    name: 'include_self_as_ubo_entry',
  });

  const smsCode = useWatch({
    control,
    name: 'sms_code',
  });

  const isAgreementChecked = useWatch({
    control,
    name: 'agreement',
  });

  const renderModalSms = () => (
    <Modal
      show={isModalOpenSms}
      closeOnEsc={false}
      onClose={() => setIsModalOpenSms(false)}>
      <Modal.Card>
        <Modal.Card.Body>
          <Controller
            control={control}
            name='sms_code'
            render={({ field: { onChange, value } }) => (
              <InputText
                onChange={onChange}
                value={value}
                label='ubo.code_label'
                placeholder='phone_verification.fields.code'
                error={smsErrorMsg?.response?.data?.message}
              />
            )}
          />
          <Checkboxes
            control={control}
            options={[{ key: 'agreement', name: t('ubo.agreement') }]}
            name='agreement'
            isDisabled={isDisabled}
            bool
            isSmall
          />
          <Control>
            <Button
              disabled={!smsCode || !isAgreementChecked || getLoading}
              loading={getLoading}
              color='success'
              onClick={handleSubmit(handleSubmitUbo)}>
              {t('ubo.btn_finish')}
            </Button>
          </Control>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );

  return (
    <Container>
      <Heading>{t('ubo.title')}</Heading>
      <Block>
        <Help>{t('ubo.help')}</Help>
      </Block>
      <Box>
        <RadioButton
          control={control}
          options={isPepSelfData()}
          name='include_self_as_ubo_entry'
          label={t('ubo.fields.is_self')}
          isDisabled={isDisabled}
          isBool
        />
      </Box>
      {fields.map((field, index) => (
        <Box key={shortid.generate()}>
          <UboForm {...{ control, index, append, field }} isDisabled />
          {index !== 0 && (
            <Button onClick={() => handleRemoveForm(index)}>Remove</Button>
          )}
        </Box>
      ))}
      {fields.length < 10 && (
        <Button color='primary' onClick={() => handleAddForm()}>
          {t('ubo.btn_add')}
        </Button>
      )}
      <Modal show={isModalOpen} closeOnEsc onClose={handleCloseModal}>
        <Modal.Card className='Ubo__modal-card'>
          <Modal.Card.Body>
            <UboForm
              {...{ control, index: 1, append, field: defaultFields.entries }}
              isDisabled={isDisabled}
              onModalClose={handleCloseModal}
            />
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      {renderModalSms()}
      <NavigationButtons
        onClickNext={handleOpenModalSmsCode}
        isLoading={!!getLoading || !!getCodeLoading}
        isNextDisabled={!isSelf && fields.length === 0}
        nextLabel='global.form.submit'
        nextColor='success'
      />
    </Container>
  );
}

export default withErrorBoundary(Ubo, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
