import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

export interface OriginOfFundsStateTypes {
  categories: string[];
  explanation?: string;
}

const initialState: OriginOfFundsStateTypes = {
  categories: [],
};

export const originOfFundsSlice = createSlice({
  name: 'originOfFunds',
  initialState,
  reducers: {
    replaceOriginOfFundsFields: (
      state,
      action: PayloadAction<OriginOfFundsStateTypes>
    ) => action.payload,
  },
});

export const { replaceOriginOfFundsFields } = originOfFundsSlice.actions;

export const getOriginOfFundsFields = (state: RootState) => state.originOfFunds;

export default originOfFundsSlice.reducer;
