import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

export interface PhoneVerificationTypes {
  phone: string;
  rawPhone: string;
  callingCode?: string;
}

const initialState: PhoneVerificationTypes = {
  phone: '',
  rawPhone: '',
  callingCode: '+41',
};

export const PhoneVerificationSlice = createSlice({
  name: 'GlobalStatus',
  initialState,
  reducers: {
    replacePhoneNumber: (
      state,
      action: PayloadAction<PhoneVerificationTypes>
    ) => action.payload,
  },
});

export const { replacePhoneNumber } = PhoneVerificationSlice.actions;

export const getPhoneNumber = (state: RootState) => state.phoneNumber;

export default PhoneVerificationSlice.reducer;
