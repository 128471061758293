import {
  Container,
  Hero,
  Heading,
  Block,
  Button,
} from 'react-bulma-components';
import { useTranslation } from 'react-i18next';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Hero>
        <Hero.Body textAlign='center'>
          <Block>
            <Heading>{t('404_page.heading')}</Heading>
            <Heading subtitle>{t('404_page.subheading')}</Heading>
          </Block>
          <Block m='6'>
            <Button color='primary' onClick={() => location.replace('/')}>
              {t('404_page.btn_back')}
            </Button>
          </Block>
        </Hero.Body>
      </Hero>
    </Container>
  );
};

export default Page404;
