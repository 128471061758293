import {
  Button,
  Container,
  Block,
  Hero,
  Heading,
} from 'react-bulma-components';
import { useTranslation } from 'react-i18next';

export const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  const { t } = useTranslation();

  const errorMessage = error.response.data.message;

  return (
    <Container>
      <Hero>
        <Hero.Body textAlign='center'>
          <Block>
            <Heading>{t('error_page.heading')}</Heading>
            {errorMessage && <Heading subtitle>{t(`${errorMessage}`)}</Heading>}
          </Block>
          <Block m='6'>
            <Button color='primary' onClick={resetErrorBoundary}>
              {t('error_page.btn_try')}
            </Button>
          </Block>
        </Hero.Body>
      </Hero>
    </Container>
  );
};

export const handleErrors = (
  error: Error,
  info: { componentStack: string }
) => {
  console.log('----- Error Boundaries -----');
  console.error('error', error);
  console.log('info', info);
};
