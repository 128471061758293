import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'Store/store';

export type InquiryOOFExplanationDocumentsTypes = {
  description: string;
  document: {
    type: string;
    files: string[];
  };
};

export interface InquiryOOFEplanationStateTypes {
  explanation: string;
  origin_of_funds_documents: InquiryOOFExplanationDocumentsTypes[];
}

export const initialDocument: InquiryOOFExplanationDocumentsTypes = {
  description: '',
  document: {
    type: 'origin_of_funds',
    files: [],
  },
};

export const initialState: InquiryOOFEplanationStateTypes = {
  explanation: '',
  origin_of_funds_documents: [initialDocument],
};

export const inquiryOOFEplanationSlice = createSlice({
  name: 'inquiryOOFEplanation',
  initialState,
  reducers: {
    replaceInquiryOOFExplanationState: (
      state,
      action: PayloadAction<InquiryOOFEplanationStateTypes>
    ) => action.payload,
  },
});

export const { replaceInquiryOOFExplanationState } =
  inquiryOOFEplanationSlice.actions;

export const getInquiryOOFExplanation = (state: RootState) =>
  state.inquiryOOFEplanation;

export default inquiryOOFEplanationSlice.reducer;
