import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

export interface IdentityStateTypes {
  date_of_birth: string;
  first_name: string;
  identity_document?: {
    files: string[];
    type: string;
  };
  is_pep?: boolean;
  pep_explanation?: string;
  last_name: string;
  nationality: string;
}

export const initialState: IdentityStateTypes = {
  date_of_birth: '',
  first_name: '',
  // is_pep: false,
  last_name: '',
  nationality: '',
};

export const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    replaceIdentityFields: (state, action: PayloadAction<IdentityStateTypes>) =>
      action.payload,
  },
});

export const { replaceIdentityFields } = identitySlice.actions;

export const getIdentityFields = (state: RootState) => state.identity;

export default identitySlice.reducer;
