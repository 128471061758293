import { useState } from 'react';
import { Form } from 'react-bulma-components';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const { Field, Control, Checkbox } = Form;

interface CheckboxesPropsTypes {
  isDisabled?: boolean;
  options: any;
  control: any;
  name: any;
  defaultChecked?: string[];
  bool?: boolean;
  isSmall?: boolean;
}

const Checkboxes = ({
  options,
  control,
  name,
  isDisabled,
  defaultChecked,
  bool,
  isSmall,
}: CheckboxesPropsTypes) => {
  const { field } = useController({
    control,
    name,
  });

  const { t } = useTranslation();

  const [values, setValues] = useState<string[]>(defaultChecked || []);

  const updateState = (value: any) => {
    const valueExist = values.includes(value.toString());

    const updatedValues = valueExist
      ? [...values.filter((cat: any) => cat !== value)]
      : [...values, value];

    if (bool) {
      const isChecked = !valueExist;
      field.onChange(isChecked);
    } else {
      field.onChange(updatedValues);
    }
    setValues(updatedValues);
  };

  return (
    <Field>
      {options.map((option: any) => (
        <Control key={option.key}>
          <Checkbox
            onChange={({ target }) => updateState(target.value)}
            key={option.key}
            type='checkbox'
            checked={values.includes(option.key)}
            value={option.key}
            disabled={isDisabled}>
            {isSmall ? <small>{t(option.name)}</small> : t(option.name)}
          </Checkbox>
        </Control>
      ))}
    </Field>
  );
};

export default Checkboxes;
