import { useEffect } from 'react';

import {
  Container,
  Heading,
  Tile,
  Notification,
  Content,
} from 'react-bulma-components';
import { useTranslation } from 'react-i18next';

import { persistor } from 'Store/store';

function Success() {
  const { t } = useTranslation();

  useEffect(() => {
    persistor.purge();
    persistor.flush();
  }, []);

  return (
    <Container>
      <Heading>{t('success.heading')}</Heading>
      <Tile kind='ancestor'>
        <Tile kind='parent'>
          <Tile kind='child' renderAs={Notification} color='success'>
            <Content>
              <Heading subtitle>{t('success.subtitle')}</Heading>
              <p>{t('success.text')}</p>
            </Content>
          </Tile>
        </Tile>
      </Tile>
    </Container>
  );
}

export default Success;
