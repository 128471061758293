const originOfFundsCategories = [
  {
    name: 'origin_of_funds.categories.employment_income',
    key: 'employment_income',
  },
  {
    name: 'origin_of_funds.categories.real_estate_sale',
    key: 'real_estate_sale',
  },
  { name: 'origin_of_funds.categories.stock_sale', key: 'stock_sale' },
  { name: 'origin_of_funds.categories.loan', key: 'loan' },
  {
    name: 'origin_of_funds.categories.sale_of_company',
    key: 'sale_of_company',
  },
  {
    name: 'origin_of_funds.categories.dividend',
    key: 'dividend',
  },
  { name: 'origin_of_funds.categories.inheritance', key: 'inheritance' },
  { name: 'origin_of_funds.categories.donation', key: 'donation' },
  { name: 'origin_of_funds.categories.pension', key: 'pension' },
  { name: 'origin_of_funds.categories.gambling', key: 'gambling' },
  { name: 'origin_of_funds.categories.other', key: 'other' },
];

export default originOfFundsCategories;
