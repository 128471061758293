import { Container, Box, Heading, Columns, Form } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';

import type { OriginOfFundsStateTypes } from 'Components/OriginOfFunds/OriginOfFundsSlice';

import {
  replaceOriginOfFundsFields,
  getOriginOfFundsFields,
} from 'Components/OriginOfFunds/OriginOfFundsSlice';
import { getGlobalStatusCurrentTask } from 'Store/GlobalStatusSlice';
import originOfFundsCategories from 'Components/OriginOfFunds/OriginOfFundsSelectData';
import {
  API_POST_OOF,
  STATUS_TASK_OFD,
  STATUS_INQUIRY_REQUEST,
} from 'Api/constants';
import { ROUTE_STEP_UBO, ROUTE_STEP_INQUIRY } from 'Router/constants';
import { formOriginOfFundsSchema } from 'GlobalComponents/Form/ValidationSchemas';
import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
import Checkboxes from 'GlobalComponents/Form/Checkboxes/Checkboxes';
import TextArea from 'GlobalComponents/Form/TextArea/TextArea';
import { useStatusChecker } from 'helpers';
import { useErrorHandler, withErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  handleErrors,
} from 'GlobalComponents/ErrorHandler/ErrorHandler';

const { Field, Label, Help } = Form;
const { Column } = Columns;

function OriginOfFunds() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const defaultFields = useSelector(getOriginOfFundsFields);
  const globalStatus = useSelector(getGlobalStatusCurrentTask);

  const isInquiry = globalStatus === STATUS_INQUIRY_REQUEST;

  const isSubmitted = useStatusChecker(STATUS_TASK_OFD);

  const [{ loading: getLoading }, postOriginOfFundsData] = useAxios(
    {
      url: API_POST_OOF,
      method: 'POST',
    },
    { manual: true }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OriginOfFundsStateTypes>({
    defaultValues: defaultFields,
    mode: 'onChange',
    resolver: yupResolver(formOriginOfFundsSchema),
  });

  const watchCategories = useWatch({
    control,
    name: 'categories',
  });

  const isDisabled = !!getLoading || isSubmitted;

  // Go next without sending data
  const handleGoNext = () => {
    navigate(isInquiry ? `/${ROUTE_STEP_UBO}` : `/${ROUTE_STEP_UBO}`);
  };

  const handleSubmitOriginOfFunds = async (data: OriginOfFundsStateTypes) => {
    try {
      await postOriginOfFundsData({ data });
      dispatch(replaceOriginOfFundsFields(data));
      navigate(isInquiry ? `/${ROUTE_STEP_INQUIRY}` : `/${ROUTE_STEP_UBO}`);
    } catch (err) {
      handleError(err);
    }
  };

  const isExplanationShouldDisplay = watchCategories.includes('other');

  return (
    <Container>
      <Heading>{t('origin_of_funds.title')}</Heading>
      <Box>
        <Columns>
          <Column>
            <Field>
              <Label>
                <span style={{ color: 'red' }}>*&nbsp;</span>
                {t('origin_of_funds.fields.categories')}
              </Label>
            </Field>
            <Checkboxes
              control={control}
              options={originOfFundsCategories}
              defaultChecked={defaultFields.categories}
              name='categories'
              isDisabled={isDisabled}
            />
            {errors.categories && (
              <Help className='has-text-danger'>
                {errors.categories?.message}
              </Help>
            )}
          </Column>
        </Columns>
        {isExplanationShouldDisplay && (
          <Columns>
            <Column>
              <Controller
                control={control}
                name='explanation'
                render={({ field: { onChange, value } }) => (
                  <TextArea
                    label='origin_of_funds.fields.explanation'
                    onChange={onChange}
                    value={value}
                    // placeholder='origin_of_funds.placeholders.explanation'
                    error={errors.explanation}
                    isDisabled={isDisabled}
                    maxLength={450}
                    required
                  />
                )}
              />
            </Column>
          </Columns>
        )}
      </Box>
      <NavigationButtons
        onClickNext={
          isSubmitted ? handleGoNext : handleSubmit(handleSubmitOriginOfFunds)
        }
        isLoading={!!getLoading}
      />
    </Container>
  );
}

export default withErrorBoundary(OriginOfFunds, {
  FallbackComponent: ErrorFallback,
  onError: handleErrors,
});
