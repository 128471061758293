import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'Store/store';

export interface InquiryStateTypes {
  answer: string;
}

export const initialState: InquiryStateTypes = {
  answer: '',
};

export const InquirySlice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    replaceInquiryState: (state, action: PayloadAction<InquiryStateTypes>) =>
      action.payload,
  },
});

export const { replaceInquiryState } = InquirySlice.actions;

export const getInquiryState = (state: RootState) => state.inquiry;

export default InquirySlice.reducer;
