import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'Store/store';

type UsStatesTypes = {
  [key: string]: {
    code: string;
    name: string;
  };
};

// Redux State formatted
export interface CountriesDataStateTypes {
  [key: string]: {
    calling_codes: string[];
    eu_member: boolean;
    iso_3166_alpha2: string;
    iso_3166_alpha3: string;
    name: any;
    risk_level: string;
    unicode_character: string;
    us_states?: UsStatesTypes[];
  };
}

const initialState: CountriesDataStateTypes = {};

export const CountriesDataSlice = createSlice({
  name: 'CountriesData',
  initialState,
  reducers: {
    replaceCountriesData: (
      state,
      action: PayloadAction<CountriesDataStateTypes>
    ) => action.payload,
  },
});

export const { replaceCountriesData } = CountriesDataSlice.actions;

export const getCountriesDataStore = (state: RootState) => state.countriesData;
export const getStatesOfUs = (state: RootState) =>
  state.countriesData.USA.us_states;

export const getEuStates = (state: RootState) => {
  const filteredCountries = Object.entries(state.countriesData).filter(
    ([, { eu_member }]) => eu_member === true
  );

  return Object.fromEntries(filteredCountries);
};

export const getCountriesCallingCodes = (state: RootState) => {
  const countriesCallingCodesFormatted = Object.values(
    state.countriesData
  ).reduce(
    (acc, { name, calling_codes, unicode_character, iso_3166_alpha3 }): any => {
      const object = {
        name,
        country_code: calling_codes,
        flag: unicode_character,
        iso3: iso_3166_alpha3,
      };

      return [...acc, object];
    },
    []
  );

  const countriesCallingCodesSorted = countriesCallingCodesFormatted.sort(
    (a: any, b: any) => a.name.localeCompare(b.name)
  );

  return countriesCallingCodesSorted;
};

export default CountriesDataSlice.reducer;
