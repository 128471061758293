import { Container, Heading, Box, Table } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NavigationButtons from 'GlobalComponents/NavigationButtons/NavigationButtons';
// import { ROUTE_STEP_ACCOUNT_CHOICE } from 'Router/constants';
import { ROUTE_STEP_PHONE } from 'Router/constants';

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Heading>{t('home.title')}</Heading>
      <Heading subtitle>{t('home.subtitle')}</Heading>
      <Box>
        <Table className='is-fullwidth'>
          <thead>
            <tr>
              <th>
                <span className='is-size-4'>
                  {t('home.table.thead.title_one')}
                </span>
              </th>
              <th className='has-text-centered'>
                <span className='is-size-4'>
                  {t('home.table.thead.title_two')}
                </span>
              </th>
              <th className='has-text-centered'>
                <span className='is-size-4'>
                  {t('home.table.thead.title_three')}
                </span>
              </th>
              <th className='has-text-centered'>
                <span className='is-size-4'>
                  {t('home.table.thead.title_four')}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='is-selected'>
              <th colSpan={4}>{t('home.table.tbody.title_one')}</th>
            </tr>
            <tr>
              <td>{t('home.table.tbody.title_two')}</td>
              <td className='has-text-centered'>CHF1k</td>
              <td className='has-text-centered'>CHF100k</td>
              <td className='has-text-centered'>CHF500k</td>
            </tr>
            <tr>
              <td>{t('home.table.tbody.title_three')}</td>
              <td className='has-text-centered'>CHF1k</td>
              <td className='has-text-centered'>CHF100k</td>
              <td className='has-text-centered'>{t('home.table.unlimited')}</td>
            </tr>
            <tr>
              <td>{t('home.table.tbody.title_four')}</td>
              <td className='has-text-centered'>CHF100k</td>
              <td className='has-text-centered'>CHF100k</td>
              <td className='has-text-centered'>{t('home.table.unlimited')}</td>
            </tr>
            <tr className='is-selected'>
              <th colSpan={4}>{t('home.table.tbody.title_five')}</th>
            </tr>
            <tr>
              <td>{t('home.table.tbody.title_six')}</td>
              <td className='has-text-centered'>-</td>
              <td className='has-text-centered'>-</td>
              <td className='has-text-centered'>
                <FontAwesomeIcon icon='check' color='green' />
              </td>
            </tr>
            <tr>
              <td>{t('home.table.tbody.title_seven')}</td>
              <td className='has-text-centered'>-</td>
              <td className='has-text-centered'>
                <FontAwesomeIcon icon='check' color='green' />
              </td>
              <td className='has-text-centered'>
                <FontAwesomeIcon icon='check' color='green' />
              </td>
            </tr>
            <tr>
              <td>{t('home.table.tbody.title_height')}</td>
              <td className='has-text-centered'>-</td>
              <td className='has-text-centered'>
                <FontAwesomeIcon icon='check' color='green' />
              </td>
              <td className='has-text-centered'>
                <FontAwesomeIcon icon='check' color='green' />
              </td>
            </tr>
          </tbody>
        </Table>
      </Box>
      <Heading size={4}>{t('home.second_title')}</Heading>
      <p>{t('home.second_text')}</p>
      <NavigationButtons
        onClickNext={() => navigate(`/${ROUTE_STEP_PHONE}`)}
        nextLabel='home.next_label'
        isBackDisplayed={false}
      />
    </Container>
  );
}

export default Home;
