import React, { useState, useEffect } from 'react';

import { Level, Button } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import SelectList from 'GlobalComponents/Form/SelectList/SelectList';

import { LANGUAGE_LIST } from 'globalData';

import './Header.scss';

interface HeaderPropsTypes {
  handleLogout: () => void;
  isLoggedIn: boolean;
}

const Header = ({ handleLogout, isLoggedIn }: HeaderPropsTypes) => {
  const { t } = useTranslation();
  const [currentLng, setCurrentLng] = useState<string>('EN');

  const handleOnLangChange = (e: any) => {
    const { value } = e.target;

    i18next.changeLanguage(value);
    setCurrentLng(value);
  };

  useEffect(() => {
    setCurrentLng(i18next.language);
  }, []);

  return (
    <header>
      <Level className='Header' py='3' px='5'>
        <Level.Side align='left'>
          <Level.Item>
            <a href='/' title='Go to homepage'>
              <img
                src={`${process.env.PUBLIC_URL}assets/images/logo.svg`}
                alt='Logo'
                className='Header__logo'
              />
            </a>
          </Level.Item>
        </Level.Side>
        <Level.Side align='right'>
          <Level.Item>
            <SelectList
              onChange={handleOnLangChange}
              value={currentLng}
              selectOptions={LANGUAGE_LIST}
            />
          </Level.Item>
          <Level.Item>
            <Button type='button' onClick={() => handleLogout()}>
              {isLoggedIn ? t('navigation.logout') : t('navigation.login')}
            </Button>
          </Level.Item>
        </Level.Side>
      </Level>
    </header>
  );
};

export default Header;
